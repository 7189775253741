import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';
import avatarImagemPadrao from 'assets/images/avatar.png';
import { Link } from 'react-router-dom';

const DropdownB = styled(DropdownButton)`
  &:focus,
  &:active {
    overflow: unset;
    box-shadow: none !important;
    background: none !important;
    outline: none;
  }
  span.icon > svg {
    font-size: 20px;
  }

  &.show > .btn-primary.dropdown-toggle {
    background-color: unset;
    border-color: unset;
  }

  button {
    background: none;
    border: none;
    &:focus,
    &:active {
      overflow: unset;
      box-shadow: none !important;
      background: none !important;
    }
    &:after {
      content: none;
    }
    &:hover {
      background: none !important;
    }
  }

  div {
    & > a:hover {
      color: #048ba8 !important;
      transition: color 0.3s;
    }
    & > a:last-child {
      border-top: ${props => (props.addline ? '1px solid lightgray' : 'unset')};
      padding-bottom: ${props => (props.addline ? '0 !important' : 'unset')};
    }
  }
`;

const AvatarImage = styled.img`
  border-radius: 50%;
`;

// eslint-disable-next-line react/prop-types
const Menu = ({ listLinks, avatar, addDivider }) => {
  return (
    <DropdownB
      addline={addDivider ? 1 : 0}
      id="dropdown-basic-button"
      title={<AvatarImage alt="avatar" src={avatar || avatarImagemPadrao} width={35} height={35} />}
    >
      {listLinks &&
        listLinks.map((l, i) => (
          <Link
            to={l.link}
            onClick={l.handleClick}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className="d-flex justify-content-between text-dark px-2 py-1"
          >
            <span>{l.label}</span>
            <span className="icon">{l.icon}</span>
          </Link>
        ))}
    </DropdownB>
  );
};

Menu.propTypes = {
  listLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string,
      handleClick: PropTypes.func,
    })
  ).isRequired,
  avatar: PropTypes.string,
};

Menu.defaultProps = {
  avatar: null,
};

export default Menu;
