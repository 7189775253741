/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { IFilter } from 'utils/filtros';
import { vagasActions } from 'store/ducks';
import { useDispatch } from 'react-redux';
import { objToArray } from 'utils/constantes';
import { Select } from 'components';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';

// eslint-disable-next-line react/prop-types
const FilterItem = ({ label, data, field, multiField, initialValue = null }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(initialValue);

  const handleChange = selectedOpts => {
    setSelectedOption(selectedOpts);
    if ((!selectedOpts || selectedOpts.length === 0) && field) {
      dispatch(vagasActions.removeFiltro(field));
      return;
    }

    const { value } = selectedOpts || [];

    dispatch(
      vagasActions.addFiltro(IFilter.in(field, multiField ? selectedOpts.map(e => e.value) : value))
    );
  };

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue]);

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  return (
    <Form.Group controlId="filtrar">
      <Form.Label>{label}</Form.Label>
      {data && (
        <Select
          options={objToArray(data)}
          isMulti={multiField}
          closeMenuOnSelect={false}
          hideSelectedOptions={multiField}
          components={multiField ? { Option, MultiValue, animatedComponents } : {}}
          onChange={handleChange}
          value={selectedOption}
          name="areas_de_atuacao"
          placeholder="---"
        />
      )}
    </Form.Group>
  );
};

export default FilterItem;
