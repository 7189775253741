/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import sagas from './sagas';
import {
  appReducer as app,
  constantesReducer as constantes,
  vagasReducer as vagas,
  empresasReducer as empresas,
  authReducer as auth,
  perfilReducer as perfil,
  curriculoReducer as curriculo,
  candidaturasReducer as candidaturas,
  noticiasReducer as noticias,
  mensagensReducer as mensagens,
} from './ducks';

const rootReducer = combineReducers({
  app,
  constantes,
  empresas,
  vagas,
  auth,
  perfil,
  curriculo,
  candidaturas,
  noticias,
  mensagens,
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composer =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(sagaMiddleware))
    : compose(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, composer);

sagaMiddleware.run(sagas);

export default store;
