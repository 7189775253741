/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { perfilActions } from 'store/ducks';
import { useToasts } from 'react-toast-notifications';
import { verifyPhoneNumber, stringToNumber } from 'utils/constantes';
import { CardItem, CardButton, CardInput, CardLabel } from '../styles';

const EnderecoForm = ({ contato }) => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [telefone, setTelefone] = useState('');
  const [telefoneFixo, setTelefoneFixo] = useState('');
  const [website, setWebsite] = useState('');

  const handleTelefone = event => {
    const number = event.target.value;
    setTelefone(verifyPhoneNumber(number));
  };

  const handleTelefoneFixo = event => {
    const number = event.target.value;
    setTelefoneFixo(verifyPhoneNumber(number, true));
  };

  const handleWebsite = event => {
    setWebsite(event.target.value);
  };

  useEffect(() => {
    if (contato) {
      setTelefone(contato.telefone && verifyPhoneNumber(contato.telefone));
      setTelefoneFixo(contato.telefone_fixo && verifyPhoneNumber(contato.telefone_fixo, true));
      setWebsite(contato.website);
    }
  }, [contato]);

  const onSubmit = data => {
    if (data) {
      // eslint-disable-next-line no-param-reassign
      data.telefone = stringToNumber(data.telefone);
      // eslint-disable-next-line no-param-reassign
      data.telefone_fixo = stringToNumber(data.telefone_fixo);
      dispatch(perfilActions.atualizarContato(data));
      addToast(`Dados salvos!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-100 d-grid">
        <div className="row">
          <CardItem className="col-xs-12 col-sm-6">
            <div className="input-group mb-2 d-flex flex-column">
              <CardLabel className="mb-0 d-flex align-items-center" htmlFor="telefone">
                Celular <span className="gold_star">*</span>
              </CardLabel>
              <CardInput
                type="text"
                className="form-control w-100"
                name="telefone"
                ref={register({
                  required: 'Por favor, informe seu celular.',
                  pattern: {
                    value: /[\d \-()]{16}/,
                    message: 'Formato inválido',
                  },
                })}
                placeholder="(00) 0 0000-0000"
                value={telefone}
                onChange={handleTelefone}
              />
            </div>
            <div className="text-danger text-left mb-2">
              <ErrorMessage errors={errors} name="telefone" />
            </div>
          </CardItem>
          <CardItem className="col-xs-12 col-sm-6">
            <div className="input-group mb-2 d-flex flex-column">
              <CardLabel className="mb-0 d-flex align-items-center" htmlFor="telefone_fixo">
                Telefone Fixo
              </CardLabel>
              <CardInput
                type="text"
                className="form-control w-100"
                name="telefone_fixo"
                ref={register({
                  pattern: {
                    value: /[\d \-()]{14}/,
                    message: 'Formato inválido',
                  },
                })}
                placeholder="(00) 0000-0000"
                value={telefoneFixo}
                onChange={handleTelefoneFixo}
              />
            </div>
            <div className="text-danger text-left mb-2">
              <ErrorMessage errors={errors} name="telefone_fixo" />
            </div>
          </CardItem>
        </div>
        <div className="row">
          <CardItem className="col-md-12">
            <div className="input-group mb-2 d-flex flex-column">
              <CardLabel className="mb-0 d-flex align-items-center" htmlFor="website">
                Facebook / Instagram
              </CardLabel>
              <CardInput
                type="text"
                className="form-control w-100"
                name="website"
                ref={register({})}
                placeholder="Informe seu website"
                value={website}
                onChange={handleWebsite}
              />
            </div>
            <div className="text-danger text-left mb-2">
              <ErrorMessage errors={errors} name="website" />
            </div>
          </CardItem>
        </div>

        <CardItem className="col-xs-12 d-flex justify-content-end">
          <CardButton className="btn btn-primary shadow-2 mb-2 mt-2" type="submit">
            Atualizar
          </CardButton>
        </CardItem>
      </form>
    </>
  );
};

export default EnderecoForm;
