import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Fields, IFilter } from 'utils/filtros';
import { useDispatch } from 'react-redux';
import { vagasActions, AppTypes } from 'store/ducks';
import { Search as SearchIcon } from 'react-feather';
import _ from 'lodash';

const SearchInput = styled.div`
  border-radius: 35px;
  width: 80%;
  height: 40px;
  box-shadow: 0 0 10px #048ba840;

  & > svg {
    color: #548bf4;
  }

  & > input {
    text-align: center;
    border: none;
    font-family: 'Avenir Next Bold';
    color: gray;
    &:focus {
      outline: none;
    }
  }
`;

// eslint-disable-next-line react/prop-types
const SearchBar = ({ title }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(title);

  const debouncedSearch = _.debounce(value => {
    dispatch(vagasActions.addFiltro(IFilter.icontains(Fields.titulo, value)));
    dispatch({ type: AppTypes.SET_VAGAS_LOADING_FiNISHED });
  }, 1000);

  const handleChange = e => {
    const { value } = e.target;
    setInputValue(value);
    dispatch({ type: AppTypes.SET_VAGAS_LOADING });
    debouncedSearch(value);
  };

  useEffect(() => {
    setInputValue(title);
  }, [title]);

  return (
    <SearchInput className="d-flex justify-content-between align-items-center bg-white px-3 w-75 mx-auto mt-2">
      <input
        className="w-100"
        value={inputValue || ''}
        placeholder="Buscar Vagas"
        onChange={handleChange}
      />
      <SearchIcon />
    </SearchInput>
  );
};

export default SearchBar;
