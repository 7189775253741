/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { filterObj } from 'utils';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import { CheckCircle, Eye, X as RemoveIcon } from 'react-feather';
import empresaIcon from 'assets/images/empresa_icon.png';
import { Actions as candidaturaActions } from 'store/ducks/candidaturas';

export const StyledCard = styled.div`
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 7px #efe7e7;
  background: #fff;
`;

export const StyledBodyCard = styled(Card)`
  display: flex;
  border: none;
  min-height: 110px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  p {
    padding: 0;
    margin: 0;
    color: #373a3c;
    font-size: 14.5px;
  }

  div.buttons {
    > * {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    font-family: 'Bebas Neue', cursive;
    right: 0;
    height: 50px;
  }

  .label {
    width: fit-content;
    padding: 3px 18px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #eec643;
    font-size: 12px;
    color: #fff;
  }

  .pcd-label {
    white-space: nowrap;
    padding: 3px 18px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #102542;
    font-size: 12px;
    color: #fff;
  }

  span {
    color: #048ba8;
    font-size: 15px;
  }
`;

export const ContentHeader = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardTitle = styled(Card.Title)`
  color: #373a3c;
  border-bottom: 1px solid #102542;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Bebas Neue', cursive;
    font-size: large;
  }
`;

export const CardDeleteButton = styled.button`
  border: none;
  background: none;
  text-decoration: none;
  &:hover {
    background: none;
    border: none;
    text-decoration: none;
  }
  &:focus,
  &:active {
    outline: none;
    background: none;
  }
`;

export const CardItem = styled.div`
  display: block;
  height: fit-content;
`;

const CandidaturaCard = ({ candidatura }) => {
  const constantes = useSelector(state => state.constantes);
  const dispatch = useDispatch();

  const dt = new Date(candidatura.vaga.ultima_atualizacao_em);

  const handleDelete = () => {
    dispatch(candidaturaActions.removerCandidatura(candidatura.vaga.id, candidatura.id));
  };

  return (
    <StyledCard className="d-flex flex-column">
      <CardTitle className="d-flex px-2 mb-0 py-1 align-items-center">
        <span className="w-100 text-center px-2">{candidatura.vaga.titulo}</span>
      </CardTitle>
      <StyledBodyCard className="flex-row">
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip id="tooltip-top">{candidatura.vaga.empresa.nome_fantasia}</Tooltip>}
        >
          <StyledBodyCard.Header
            style={{ borderRight: '1px dashed #102542', minHeight: 100 }}
            className="d-flex flex-column justify-content-center align-items-center border-bottom-0 bg-transparent"
          >
            <img
              style={{ width: '70px', maxHeight: '70px' }}
              src={candidatura.vaga.empresa.thumbnail || empresaIcon}
              alt={candidatura.vaga.empresa.nome_fantasia}
            />
          </StyledBodyCard.Header>
        </OverlayTrigger>
        <StyledBodyCard.Body className="d-flex justify-content-between flex-column pt-1">
          <ContentHeader className="d-flex flex-column">
            <small style={{ color: 'gray' }} className="d-flex justify-content-center w-100">
              {dt.toLocaleDateString('pt-BR', { month: 'long', day: 'numeric', year: 'numeric' })}
            </small>
            <div className="d-flex border-bottom-0 align-items-strech w-100 p-0 bg-white">
              <div className="labels">
                <div className="label">
                  {filterObj(
                    constantes.regime_de_contratacao,
                    candidatura.vaga.regime_de_contratacao
                  )}
                </div>
                {candidatura.vaga.vaga_para_portadores_de_necessidades_especiais && (
                  <div className="pcd-label d-sm-block">PCD</div>
                )}
              </div>
            </div>
          </ContentHeader>
          <Content>
            <CardItem>
              <p className="w-100">Salário</p>
              <span>
                {`${!Number.isNaN(candidatura.vaga.salario) ? 'R$ ' : ''}${
                  candidatura.vaga.salario
                }`}
              </span>
            </CardItem>
          </Content>
          <div
            className="buttons d-flex justify-content-around align-items-center"
            style={{ position: 'absolute', bottom: '7px', right: '7px' }}
          >
            {candidatura.selecionado && (
              <OverlayTrigger
                key="selected"
                placement="bottom"
                overlay={<Tooltip id="tooltip-bottom">Candidato Selecionado!</Tooltip>}
              >
                <CheckCircle style={{ color: 'green' }} size={30} />
              </OverlayTrigger>
            )}
            <OverlayTrigger
              key="remove"
              placement="bottom"
              overlay={<Tooltip id="tooltip-bottom">Remover Candidatura</Tooltip>}
            >
              <CardDeleteButton onClick={handleDelete}>
                <RemoveIcon style={{ color: '#dc3545' }} size={25} />
              </CardDeleteButton>
            </OverlayTrigger>
            <OverlayTrigger
              key="details"
              placement="bottom"
              overlay={<Tooltip id="tooltip-bottom">Detalhes</Tooltip>}
            >
              <Link to={`/vaga/${candidatura.vaga.id}`}>
                <Eye style={{ color: '#048ba8' }} size={25} />
              </Link>
            </OverlayTrigger>
          </div>
        </StyledBodyCard.Body>
      </StyledBodyCard>
    </StyledCard>
  );
};

export default CandidaturaCard;
