import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { logOut } from 'services/auth';
import { DEMO, abbreviateName } from 'utils/constantes';
import { getUser, getUserToken } from 'utils/storage';
import { Menu, GeneralModal as MessagesModal, MenuIcon, useWindowDimensions } from 'components';
import { LogOut, FileText, Users, Edit, LogIn, ChevronRight } from 'react-feather';
import { ChatFill } from 'react-bootstrap-icons';
import { Mensagens } from 'pages';
import { Tooltip, OverlayTrigger, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import avatarImagemPadrao from 'assets/images/avatar.png';
import logoemprega from 'assets/images/logoemprega.png';
import logocidade from 'assets/images/logo.png';

const List = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  align-items: center;
  justify-content: center;
  padding-left: 0px;

  &.main {
    align-items: stretch;
    li {
      display: flex;
      align-items: center;
    }
  }

  & li > a {
    &.gold {
      color: #eec643;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &:not(.mobile) {
    li:not(.msg-icon) {
      padding: 0 10px !important;
    }
  }

  & button.btn-w-outline {
    &:focus {
      outline: none;
    }
  }
`;

const MsgBtn = styled.button`
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
  svg {
    color: #24c324;
    position: relative;
    margin-top: 2.5px;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
    }
  }
`;

const Span = styled.span`
  font-family: initial;
  font-size: 20px;
  margin: 0;

  &.user-name {
    font-family: Avenir Next;
    font-size: 14px;
    font-weight: 500;
    font-size: 0.85rem;
    letter-spacing: 0.5px;
  }
`;

const Nav = styled.nav`
  position: fixed;
  z-index: 99;
  width: 100%;
  min-height: 50px;
  top: 0;
  background-color: #102542;
  height: ${props => (props.isMenuActive ? '100vh' : '50px')};
  transition-duration: 0.5s;

  a.active {
    background: none !important;
    color: #eec643 !important;
    padding: 0;
  }
`;

const logoutAndredirect = async () => {
  await logOut();
  // eslint-disable-next-line no-undef
  window.location.reload();
};

const SlimNav = () => {
  // eslint-disable-next-line no-undef
  let user = getUser();
  const token = getUserToken();
  const [visible, setVisible] = useState(false);
  const newUser = useSelector(state => state.app.updateUser);
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width < 768);
  const [isActiveMI, setIsActiveMI] = useState(false);

  useEffect(() => {
    setIsMobile(width < 768);
  }, [width]);

  const loggedInList = [
    { link: '/perfilusuario', label: 'Meus Dados', icon: <Users size={20} /> },
    {
      link: '/curriculo',
      label: 'Meu Curriculo',
      icon: <FileText size={20} />,
    },
    { link: '/candidaturas', label: 'Candidaturas', icon: <Edit size={20} /> },
    {
      link: DEMO.BLANK_LINK,
      label: 'Sair',
      handleClick: () => logoutAndredirect(),
      icon: <LogOut size={20} />,
    },
  ];

  useEffect(() => {
    user = getUser();
  }, [newUser]);

  const styleMainLink = {
    boxShadow: 'none',
  };

  return (
    <>
      <MessagesModal title="Minhas Mensagens" visible={visible} setVisible={setVisible}>
        <Mensagens />
      </MessagesModal>

      <Nav isMenuActive={isMobile && isActiveMI} style={{ fontFamily: 'Bebas neue' }}>
        {isMobile && (
          <div className="d-flex justify-content-between align-items-center" style={{ height: 50 }}>
            <Link to="/">
              <img src={logoemprega} alt="logo do site" width={85} />
            </Link>
            <MenuIcon isActive={isActiveMI} handleClick={() => setIsActiveMI(!isActiveMI)} />
          </div>
        )}

        <Container
          className={`d-${
            isMobile && isActiveMI ? 'flex' : 'none'
          } flex-column-reverse justify-content-around px-0 h-100`}
          style={{ fontSize: '30px' }}
        >
          <List className="d-flex flex-column justify-content-around mobile">
            <li className="py-4">
              <NavLink exact to="/" className="text-white" activeStyle={styleMainLink}>
                Vagas
              </NavLink>
            </li>
            <li className="py-4">
              <NavLink exact to="/empresas" className="text-white" activeStyle={styleMainLink}>
                Empresas
              </NavLink>
            </li>
            <li className="py-4">
              <NavLink exact to="/noticias" className="text-white" activeStyle={styleMainLink}>
                Notícias
              </NavLink>
            </li>
          </List>

          <List className="flex-column">
            {!token && (
              <>
                <li>
                  <Link
                    to="/entrar"
                    className="text-white"
                    ref={node => {
                      if (node) {
                        node.style.setProperty('color', '#73FF5C', 'important');
                      }
                    }}
                  >
                    <span>Entrar </span>
                    <LogIn size={30} />
                  </Link>
                </li>
                <li style={{ fontFamily: 'Avenir Next Bold' }}>
                  <span style={{ fontSize: '15px', color: '#048ba8' }}>Não possui conta? </span>
                  <Link
                    to="/cadastro"
                    className="gold"
                    style={{ fontSize: '15px', color: '#73FF5C' }}
                  >
                    Cadastre-se
                  </Link>
                </li>
              </>
            )}
            {token && (
              <>
                <li className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex align-items-center">
                    <img
                      alt="avatar"
                      className="rounded-circle"
                      src={user.foto || avatarImagemPadrao}
                      width={60}
                      height={60}
                    />
                    {user && (
                      <Span
                        className="text-white user-name ml-2"
                        style={{ fontFamily: 'Nunito', fontSize: 25 }}
                      >
                        {abbreviateName(user.nome_completo)}
                      </Span>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => logoutAndredirect()}
                    className="d-flex align-items-center btn-w-outline"
                    style={{
                      background: 'none',
                      border: 'none',
                    }}
                  >
                    <span style={{ color: '#c9465f', fontSize: 20 }}>Sair</span>
                    <LogOut color="#c9465f" />
                  </button>
                </li>
                <li className="px-0 msg-icon w-100 pt-3">
                  {loggedInList &&
                    loggedInList.slice(0, -1).map((l, i) => (
                      <Link
                        to={l.link}
                        onClick={l.handleClick}
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        style={{
                          alignItems: 'center',
                          fontFamily: 'Avenir Next Bold',
                          fontSize: '15px',
                          color: '#048ba8',
                        }}
                        className="d-flex px-2 py-1"
                      >
                        <ChevronRight />
                        <span
                          style={{
                            transform: 'translate(0px, 3px)',
                          }}
                        >
                          {l.label}
                        </span>
                      </Link>
                    ))}
                  <button
                    type="button"
                    onClick={() => setVisible(true)}
                    className="d-flex px-2 py-1 btn-w-outline"
                    style={{
                      alignItems: 'center',
                      fontFamily: 'Avenir Next Bold',
                      fontSize: '15px',
                      color: '#048ba8',
                      background: 'none',
                      border: 'none',
                    }}
                  >
                    <ChevronRight />
                    <span
                      style={{
                        transform: 'translate(0px, 3px)',
                      }}
                    >
                      Mensagens
                    </span>
                  </button>
                </li>
              </>
            )}
          </List>
        </Container>

        <Container
          className={`d-${!isMobile ? 'flex' : 'none'} justify-content-between px-0 h-100`}
        >
          <List className="main">
            <li>
              <NavLink exact to="/" className="text-white px-0" activeStyle={styleMainLink}>
                Vagas
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/empresas" className="text-white" activeStyle={styleMainLink}>
                Empresas
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/noticias" className="text-white" activeStyle={styleMainLink}>
                Notícias
              </NavLink>
            </li>
          </List>

          <List>
            <Link to="/">
              <img className="mx-1" src={logoemprega} alt="logo do site" width={85} />
            </Link>
            <img
              className="mx-1 d-none d-sm-block"
              src={logocidade}
              alt="logo da cidade"
              width={85}
            />
          </List>

          <List className="px-1">
            {!token && (
              <>
                <li>
                  <Link to="/entrar" className="text-white">
                    Entrar
                  </Link>
                </li>
                <li>
                  <Link to="/cadastro" className="gold">
                    Cadastre-se
                  </Link>
                </li>
              </>
            )}
            {token && (
              <>
                <li className="px-0 msg-icon">
                  <MsgBtn type="button" className="px-0" onClick={() => setVisible(true)}>
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-bottom">Mensagens</Tooltip>}
                    >
                      <ChatFill size={25} />
                    </OverlayTrigger>
                  </MsgBtn>
                </li>
                <li className="d-flex align-items-center">
                  {user && (
                    <Span className="text-white user-name">
                      {abbreviateName(user.nome_completo)}
                    </Span>
                  )}
                  <Menu addDivider listLinks={loggedInList} avatar={user ? user.foto : null} />
                </li>
              </>
            )}
          </List>
        </Container>
      </Nav>
    </>
  );
};

export default SlimNav;
