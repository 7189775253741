/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import React from 'react';
import styled from 'styled-components';

const MapLayer = styled(Map)`
  overflow: hidden;
  background: lightgray;
  max-width: 400px;
  height: 230px;
  z-index: 0;
`;

const MapContainer = ({ zoom, marker }) => {
  const position = marker && [marker.lat, marker.lng];
  return (
    <>
      {marker ? (
        <MapLayer className="w-100 my-3" center={position} zoom={zoom}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapLayer>
      ) : (
        <div className="w-100 py-5 text-center text-danger">Localização não encontrada</div>
      )}
    </>
  );
};

export default MapContainer;
