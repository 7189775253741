/* eslint-disable no-undef */
export const clearStorage = () => {
  localStorage.clear();
};

export const setUser = user => {
  return localStorage.setItem('user', JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const getUserToken = () => {
  return localStorage.getItem('user_token');
};

export const setUserToken = token => {
  return localStorage.setItem('user_token', token);
};
