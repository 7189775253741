const INITIAL_STATE = {};

export const Types = {
  async: {
    ATUALIZAR_DT_PESSOAIS: 'PERFIL/ATUALIZAR_PESSOAIS_ASYNC',
    ATUALIZAR_ENDERECO: 'PERFIL/ATUALIZAR_ENDERECO_ASYNC',
    ATUALIZAR_CONTATO: 'PERFIL/ATUALIZAR_CONTATO_ASYNC',
    ATUALIZAR_FOTO: 'PERFIL/ATUALIZAR_FOTO_ASYNC',
    PEGAR_PERFIL: 'PERFIL/PEGAR_PERFIL_ASYNC',
  },
  sync: {
    ATUALIZAR_DT_PESSOAIS: 'PERFIL/ATUALIZAR_PESSOAIS_SYNC',
    ATUALIZAR_ENDERECO: 'PERFIL/ATUALIZAR_ENDERECO_SYNC',
    ATUALIZAR_CONTATO: 'PERFIL/ATUALIZAR_CONTATO_SYNC',
    PEGAR_PERFIL: 'PERFIL/PEGAR_PERFIL_SYNC',
  },
};

export const Actions = {
  atualizarPerfil: data => ({ type: Types.async.ATUALIZAR_DT_PESSOAIS, payload: data }),
  atualizarEndereco: data => ({ type: Types.async.ATUALIZAR_ENDERECO, payload: data }),
  atualizarContato: data => ({ type: Types.async.ATUALIZAR_CONTATO, payload: data }),
  atualizarFoto: data => ({ type: Types.async.ATUALIZAR_FOTO, payload: data }),
  pegarPefil: () => ({ type: Types.async.PEGAR_PERFIL }),
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.ATUALIZAR_ENDERECO:
      return { ...state, endereco: action.payload.endereco };
    case Types.sync.ATUALIZAR_CONTATO:
      return { ...state, contato: action.payload.contato };
    case Types.sync.ATUALIZAR_DT_PESSOAIS:
      return { ...state, pessoais: action.payload.pessoais };
    case Types.sync.PEGAR_PERFIL:
      return action.payload;
    default:
      return state;
  }
};

export default authReducer;
