import { takeLatest, call, put, select, take, all } from 'redux-saga/effects';
import { listarEmpresas, filtrarEmpresas } from 'services/empresas';
import { Types } from '../ducks/empresas';
import { Types as AppTypes } from '../ducks/app';

function* listarEmpresasAsync({ payload }) {
  try {
    const { data } = yield call(listarEmpresas, payload);
    const buildPayload = {
      page: data.data.links.next
        ? parseInt(10, data.data.links.next.substr(data.data.links.next.lastIndexOf('=') + 1))
        : '',
      data: data.data.empresas,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: Types.sync.LISTAR, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* filtrarEmpresasAsync() {
  try {
    const filtros = yield select(state => state.empresas.filtros);
    const query = filtros.join('&');

    const { data } = yield call(filtrarEmpresas, query);
    const buildPayload = {
      page: data.data.links.next
        ? parseInt(10, data.data.links.next.substr(data.data.links.next.lastIndexOf('=') + 1))
        : '',
      data: data.data.empresas,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: Types.sync.FILTRAR, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* watchRemoveFiltro() {
  while (true) {
    yield take(Types.sync.REMOVE_FILTRO);
    yield call(filtrarEmpresasAsync);
  }
}

function* watchAddFiltro() {
  while (true) {
    yield take(Types.sync.ADD_FILTRO);
    yield call(filtrarEmpresasAsync);
  }
}

function* empresasSaga() {
  yield takeLatest(Types.async.LISTAR, listarEmpresasAsync);
  yield all([watchAddFiltro(), watchRemoveFiltro()]);
}

export default empresasSaga;
