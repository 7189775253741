import { clearStorage, setUserToken, setUser } from 'utils/storage';

export const logOut = () => {
  clearStorage();
};

export const setUpAfterLogin = user => {
  setUser(user.pessoa);
  setUserToken(user.token);
};
