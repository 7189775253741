export const Types = {
  RANGE: 'RANGE',
  IN: 'IN',
  ICONTAINS: 'CONTAINS',
};

export const Fields = {
  areasDeAtuacao: 'areas_de_atuacao',
  titulo: 'titulo',
  salario: 'salario',
  nivelDeEscolaridade: 'nivel_de_escolaridade',
  regimeDeContratacao: 'regime_de_contratacao',
  nomeFantasia: 'nome_fantasia',
};

export const IFilter = {
  range: (field = '', begin = 0, end = 0) => ({
    type: Types.RANGE,
    args: {
      field,
      begin,
      end,
    },
  }),
  in: (field = '', query = '') => ({
    type: Types.IN,
    args: {
      field,
      query,
    },
  }),
  icontains: (field = '', query = '') => ({
    type: Types.ICONTAINS,
    args: {
      field,
      query,
    },
  }),
};

const rangeQuery = ({ field, begin, end }) => {
  return `${field}__range=${begin},${end}`;
};

const InQuery = ({ field, query }) => {
  return `${field}__in=${query}`;
};

const icontainsQuery = ({ field, query }) => {
  return `${field}__icontains=${query}`;
};

const buildQuery = filter => {
  switch (filter.type) {
    case Types.IN:
      return InQuery(filter.args);
    case Types.RANGE:
      return rangeQuery(filter.args);
    case Types.ICONTAINS:
      return icontainsQuery(filter.args);
    default:
      return null;
  }
};

export default buildQuery;
