/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { AppStoreIcon } from 'components';
import { X } from 'react-feather';

const ModalBody = styled(Modal.Header)`
  display: block;
  text-align: center;
  border: none;
  .box {
    padding: 50px;
  }
`;

const LinkButton = styled.a`
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  padding: 5px;
  color: #222;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
  border: none;
`;
const StoresModal = ({ visible, setVisible }) => {
  return (
    <Modal show={visible} dialogClassName="modal-90w" centered onHide={() => setVisible(false)}>
      <ModalBody>
        <LinkButton onClick={() => setVisible(false)}>
          <X />
        </LinkButton>
        <div className="box">
          <h3>
            Baixe o nosso
            <br />
            Aplicativo
          </h3>
          <a
            href="https://play.google.com/store/apps/details?id=com.cgsconsultoria.querotrabalhar&hl=en"
            target="blank"
          >
            <AppStoreIcon store="google-play" />
          </a>
          <a href="https://apps.apple.com/us/app/quero-trabalhar/id1474232193?l=pt&ls=1">
            <AppStoreIcon />
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StoresModal;
