import api from 'config/api';
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const atualizarPerfil = data => api.put(`/candidatos/perfil`, data);
export const atualizarEndereco = data => api.put(`/candidatos/endereco`, data);
export const atualizarContato = data => api.put(`/candidatos/contato`, data);
export const atualizarFoto = data => api.put(`/candidatos/perfil`, data);
export const pegarPerfil = () => api.get('/candidatos/perfil');

export const pegarEndereco = cep => axios.get(`https://viacep.com.br/ws/${cep}/json/`);
