const INITIAL_STATE = {
  objetivo: null,
  formacao: null,
  experiencia_profissional: null,
  idiomas: null,
  qualificacoes: null,
  informacoes_adicionais: null,
};

export const Types = {
  async: {
    ATUALIZAR_TOPICO: 'CURRICULO/ATUALIZAR_ASYNC',
    PEGAR_TOPICOS: 'CURRICULO/PEGAR_ASYNC',
  },
  sync: {
    ATUALIZAR_TOPICO: 'CURRICULO/ATUALIZAR_SYNC',
    PEGAR_TOPICOS: 'CURRICULO/PEGAR_SYNC',
  },
};

// Actions Creators
export const Actions = {
  atualizarTopico: data => ({ type: Types.async.ATUALIZAR_TOPICO, payload: data }),
  pegarTopico: () => ({ type: Types.async.PEGAR_TOPICOS }),
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.PEGAR_TOPICOS:
      return action.payload;
    case Types.sync.ATUALIZAR_TOPICO:
      return action.payload;
    default:
      return state;
  }
};
