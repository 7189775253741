import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mensagensActions } from 'store/ducks';
import styled from 'styled-components';
import { ChevronDoubleRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Eye } from 'react-feather';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CardMsg = styled.div`
  border-radius: 5px;
  border: 1px solid #c29100;

  .gold {
    color: #c29100;
  }

  .blue {
    color: #102542;
  }
`;

const CardHeader = styled.div`
  color: #102542;
  border-bottom: 1px solid #c29100;
`;

const CardContent = styled.div`
  & > div {
    & > div:first-of-type {
      background: lightgray;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      a {
        color: #048ba8;
        &:hover {
          text-decoration: none;
        }
      }
    }
    border-radius: 5px;
  }
`;

const CardItem = styled.div`
  background: #f1f1f1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const Msg = styled.span`
  border-radius: 10px;
  color: darkblue;
  background: lightgreen;
`;

const Mensagems = () => {
  const { mensagens } = useSelector(state => state.mensagens);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mensagensActions.pegarMensagens());
  }, [dispatch]);

  const removeDubleObjs = objs =>
    objs.filter((emp, index) => {
      const tempEmp = JSON.stringify(emp);
      return index === objs.findIndex(obj => JSON.stringify(obj) === tempEmp);
    });

  const empresas = removeDubleObjs(mensagens.map(m => m.candidatura.vaga.empresa));

  return (
    <div className="d-flex flex-column justify-content-around">
      {empresas.length ? (
        empresas.map(empresa => {
          const msgs = mensagens.filter(m => m.candidatura.vaga.empresa.id === empresa.id);
          const vagas = removeDubleObjs(msgs.map(m => m.candidatura.vaga));

          return (
            <CardMsg className="py-1 mb-3" key={empresa.id}>
              <CardHeader className="d-flex justify-content-between align-items-center w-100 px-2">
                <strong>{empresa.nome_fantasia}</strong>
                <Link to={`/empresas/${empresa.id}`} className="d-flex align-items-center gold">
                  <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={<Tooltip id="tooltip-right">Visualizar empresa</Tooltip>}
                  >
                    <Eye />
                  </OverlayTrigger>
                </Link>
              </CardHeader>
              <CardContent className="d-flex flex-column px-1">
                {vagas.map(vaga => (
                  <div className="d-flex flex-column mt-1 mb-2" key={vaga.id}>
                    <div className="d-flex justify-content-between px-1">
                      <div>
                        <span className="gold">vaga: </span>
                        <span className="blue">{vaga.titulo}</span>
                      </div>
                      <Link to={`/vaga/${vaga.id}`} className="d-flex align-items-center">
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip id="tooltip-right">Visualizar vaga</Tooltip>}
                        >
                          <Eye />
                        </OverlayTrigger>
                      </Link>
                    </div>
                    <CardItem className="d-flex flex-column px-1 py-2">
                      {msgs
                        .filter(m => m.candidatura.vaga.id === vaga.id)
                        .map((m, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div className="d-flex align-items-center mb-1" key={i}>
                            <ChevronDoubleRight className="gold" />
                            <Msg className="py-1 px-2 align-self-start">{m.texto}</Msg>
                          </div>
                        ))}
                    </CardItem>
                  </div>
                ))}
              </CardContent>
            </CardMsg>
          );
        })
      ) : (
        <div style={{ color: '#00516a' }} className="w-100 text-center font-weight-bold">
          Não há mensagens no momento
        </div>
      )}
    </div>
  );
};

export default Mensagems;
