import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const AuthWrapper = styled.div`
  height: 100vh;
  font-family: 'Avenir Next Bold';
  background-color: #102542;

  &:not(.signInAuthWrapper)::before {
    content: '';
    display: block;
  }

  .facebook-button {
    background: #3b5998;
    color: white;
    border: none;
    border-radius: 25px;
    margin-bottom: 20px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding-right: 10px;

    &:focus {
      outline: none;
    }

    & > i {
      padding: 10px;
      font-size: 25px;
    }
  }

  .title {
    color: #eec643;
  }

  p {
    &.small_text {
      max-width: 250px;
      font-size: 13px;
      text-align: center;
      margin: 0 auto 15px auto;
    }
  }

  span {
    &.links {
      font-size: 14px;
      & > a {
        color: #eec643;
        transition: text-shadow 0.3s;
        &:hover {
          text-decoration: none;
          text-shadow: 0 0 5px #eec643;
          transition: text-shadow 0.3s;
        }
      }
    }
  }
`;

export const Triangle = styled.div`
  height: 0px;
  border-style: solid;
  position: absolute;
  filter: drop-shadow(1px 1px 5px);

  &.left {
    border-width: 60px 0 60px 100px;
    border-color: transparent transparent transparent #102542;
    top: 20px;
    left: 0;
  }

  &.right {
    border-width: 60px 100px 60px 0;
    border-color: transparent #102542 transparent transparent;
    bottom: 20px;
    right: 0;
  }
`;

export const Button = styled.button`
  background: #eec643;
  transition: box-shadow 0.3s;
  border: none;
  border-radius: 25px;
  color: #102542;
  &:hover {
    color: #102542;
    background: #eec643;
    box-shadow: 0 0 5px #eec643;
    transition: box-shadow 0.3s;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const Input = styled.input`
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  border: none;
  &.signIn {
    box-sizing: content-box;
    background: lightgray;
  }
`;
