/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const ModalHeader = styled(Modal.Header)`
  box-shadow: 0 0 10px lightgrey;
  color: #c29100;
  button {
    margin-left: 0 !important;
  }
`;

const GeneralModal = ({ visible, setVisible, title, children }) => (
  <>
    <Modal
      size="lg"
      show={visible}
      onHide={() => setVisible(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ModalHeader closeButton>
        <Modal.Title className="mx-auto" id="example-modal-sizes-title-lg">
          <h4
            style={{
              fontFamily: 'Avenir',
              fontWeight: 'bold',
              fontSize: '1.2rem',
            }}
          >
            {title}
          </h4>
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  </>
);

export default GeneralModal;
