/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, ErrorMessage } from 'react-hook-form';
import api from 'config/api';
import logoemprega from 'assets/images/logoemprega.png';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { TiArrowBack } from 'react-icons/ti';
import { AuthWrapper, Input, Button } from '../styles';

// eslint-disable-next-line react/prop-types
const ResetPass = ({ history }) => {
  const { register, handleSubmit, triggerValidation, getValues, errors, formState } = useForm();
  const [step, setStep] = useState('gerar_token');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState(false);
  const auth = useSelector(state => state.auth);

  const showError = msg => {
    setError(msg);

    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const handleReturn = () => {
    switch (step) {
      case 'validar_token':
        setStep('gerar_token');
        break;
      case 'recuperar_senha':
        setStep('validar_token');
        break;
      default:
    }
  };

  const onSubmit = data => {
    switch (step) {
      case 'gerar_token': {
        api
          .post('/gerar-token-de-recuperacao', data)
          .then(() => {
            setEmail(data.email);
            setStep('validar_token');
          })
          .catch(e => {
            if (e.response) {
              showError(e.response.data && e.response.data.message);
            }
          });
        break;
      }
      case 'validar_token': {
        const newData = { email, token: data.token };
        api
          .post('/validar-token-de-recuperacao', newData)
          .then(() => {
            setToken(data.token);
            setStep('recuperar_senha');
          })
          .catch(e => {
            if (e.response) {
              showError(e.response.data && e.response.data.message);
            }
          });
        break;
      }
      case 'recuperar_senha': {
        const newData = { email, token, senha: data.senha };
        api
          .post('/recuperar-senha', newData)
          .then(() => {
            history.push('/entrar');
            // dispatch(authActions.register(response.data.data));
          })
          .catch(e => {
            if (e.response) {
              showError(e.response.data && e.response.data.message);
            }
          });
        break;
      }
      default:
    }
  };

  const repeatVal = senhaConfirmacao =>
    senhaConfirmacao === getValues().senha || 'Essa senha deve ser igual a anterior!';

  const minLengthIpt = senha =>
    (senha && senha.length >= 6) || 'A senha deve conter no mínimo 6 dígitos';

  const validateRepeat = () => {
    if (formState.isSubmitted) {
      triggerValidation({ name: 'senhaConfirmacao' });
    }
  };

  useEffect(() => {
    if (auth.token) {
      history.push('/');
    }
  }, [auth, history]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (localStorage.getItem('user_token')) {
      // eslint-disable-next-line react/prop-types
      history.push('/');
    }
    // eslint-disable-next-line no-undef
  }, [localStorage.getItem('user_token'), history]);

  // eslint-disable-next-line no-undef
  if (localStorage.getItem('user_token')) {
    return null;
  }

  return (
    <AuthWrapper className="m-0 text-white d-flex flex-column justify-content-around align-items-center w-100">
      <Button
        type="submit"
        style={{ position: 'absolute', top: 20, right: 20, color: '#102542' }}
        onClick={() => history.goBack()}
        className="btn px-3 d-flex justify-content-center align-items-center"
      >
        <TiArrowBack size={30} />
        <span style={{ transform: 'translate(0px, 3px)' }}>Voltar</span>
      </Button>
      <div className="card bg-transparent border-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-body text-center">
            <div className="mb-4">
              <i className="feather icon-user-plus auth-icon" />
            </div>
            <h4 className="mb-0 title">Recuperar Senha</h4>
            {step === 'gerar_token' && (
              <>
                <p className="small_text">Um código será enviado para o seu email</p>
                <div className="input-group mb-2">
                  <Input
                    type="email"
                    className="form-control"
                    name="email"
                    ref={register({ required: 'Por favor, informe seu email.' })}
                    placeholder="Informe seu email"
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage errors={errors} name="email" />
                </div>
              </>
            )}
            {step === 'validar_token' && (
              <>
                <p className="small_text">Informe o código recebido por email</p>
                <div className="input-group mb-2">
                  <Input
                    type="text"
                    className="form-control w-100"
                    name="token"
                    ref={register({ required: 'Informe o código recebido por email' })}
                    placeholder="Token de recuperação"
                    onChange={validateRepeat}
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage errors={errors} name="token" />
                </div>
              </>
            )}
            {step === 'recuperar_senha' && (
              <>
                <p className="small_text">Informe uma nova senha</p>
                <div className="input-group mb-2">
                  <Input
                    type="password"
                    className="form-control"
                    name="senha"
                    ref={register({ required: 'Informe uma nova senha', validate: minLengthIpt })}
                    placeholder="Senha"
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage errors={errors} name="senha" />
                </div>
                <div className="input-group mb-2">
                  <Input
                    type="password"
                    className="form-control"
                    name="senhaConfirmacao"
                    ref={register({
                      required: 'Confirme a sua senha',
                      validate: repeatVal || minLengthIpt,
                    })}
                    placeholder="Confirme a senha"
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage errors={errors} name="senhaConfirmacao" />
                </div>
              </>
            )}
            <div>
              <span className="mx-auto links">
                Lembrou da senha?<a href="/entrar"> Cique aqui para entrar</a>
              </span>
            </div>

            {step !== 'gerar_token' && (
              <Button
                className="btn btn-secondary shadow-2 mt-3 pl-1 mr-2"
                type="submit"
                onClick={handleReturn}
              >
                <ChevronLeft />
                Voltar
              </Button>
            )}
            <Button
              className={`btn btn-primary shadow-2 mt-3 ${
                step !== 'recuperar_senha' ? 'pr-1' : ''
              }`}
              type="submit"
            >
              {step === 'recuperar_senha' ? 'Recuperar' : 'Avançar'}
              {step !== 'recuperar_senha' ? <ChevronRight /> : ''}
            </Button>
            {error && (
              <div className="text-danger text-center mb-2">
                <span>{error}</span>
              </div>
            )}
          </div>
        </form>
        <div className="text-center w-100">
          Passo {step === 'recuperar_senha' ? '3' : step === 'gerar_token' ? '1' : '2'} de 3
        </div>
      </div>
      <img src={logoemprega} alt="logo do site" width={150} />
    </AuthWrapper>
  );
};

export default ResetPass;
