const INITIAL_STATE = {
  token: null,
  user: null,
};

export const Types = {
  sync: {
    LOGIN: 'AUTH/LOGIN',
    LOGOUT: 'AUTH/LOGOUT',
    REGISTER: 'AUTH/REGISTER',
    ERROR: 'AUTH/RESET_PASS',
  },
};

export const Actions = {
  login: data => ({ type: Types.sync.LOGIN, payload: data }),
  logout: data => ({ type: Types.sync.LOGOUT, payload: data }),
  register: data => ({ type: Types.sync.REGISTER, payload: data }),
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.LOGIN:
      return action.payload;
    case Types.sync.LOGOUT:
      return INITIAL_STATE;
    case Types.sync.REGISTER:
      return { ...state, user: action.payload.pessoa, token: action.payload.token };
    case Types.sync.ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default authReducer;
