const INITIAL_STATE = {
  loading: false,
  error: null,
  toastr: {
    message: '',
    status: '',
    abled: false,
  },
  vagas: {
    loading: false,
  },
  updateUser: false,
};

export const Types = {
  SET_VAGAS_LOADING: 'app/vagas/loading',
  SET_VAGAS_LOADING_FiNISHED: 'app/vagas/loading_fineshed',
  SET_LOADING: 'app/loading',
  SET_ERROR: 'app/set_error',
  SET_TOASTR: 'app/set_toastr',
  FINISH_LOADING: 'app/finish-loading',
  UPD_USER: 'app/upd_user',
};

// Actions Creators
export const Actions = {
  setAppLoading: () => ({ type: Types.LOADING }),
  setAppLoadingComplete: () => ({ type: Types.FINISH_LOADING }),
  setAppError: err => ({ type: Types.SET_STATUS, payload: err }),
  setAppToastr: toastr => ({ type: Types.SET_TOASTR, payload: toastr }),
  updateUser: isToUpdate => ({ type: Types.UPD_USER, payload: isToUpdate }),
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.LOADING:
      return { ...state, loading: true };
    case Types.FINISH_LOADING:
      return { ...state, loading: false };
    case Types.SET_TOASTR:
      return { ...state, toastr: action.payload };
    case Types.SET_VAGAS_LOADING:
      return { ...state, vagas: { loading: true } };
    case Types.SET_VAGAS_LOADING_FiNISHED:
      return { ...state, vagas: { loading: false } };
    case Types.SET_ERROR:
      return { ...state, error: action.payload };
    case Types.UPD_USER:
      return { ...state, updateUser: action.payload };
    default:
      return state;
  }
};
