/* eslint-disable react/prop-types */
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

export const ModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: center;
  border: none;

  h3 {
    font-size: 20px;
    font-weight: 400;
  }
`;

export const ModalBody = styled(Modal.Header)`
  display: block;
  border: none;
  p {
    color: #707070;
    font-size: 15px;
    margin: 3px;
  }

  address {
    margin: 25px;
    margin-bottom: 0px;

    h4 {
      font-size: 16px;
    }
    p {
      color: #1271a3;
    }
  }
`;

export const LinkButton = styled.a`
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: pointer;
  padding: 5px;
  color: #414141;
  background: transparent;
  border: none;
  padding: 0;
`;
