/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { useDispatch } from 'react-redux';
import { vagasActions, AppTypes } from 'store/ducks';
import styled from 'styled-components';

const Thumb = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #aaa;
  &:focus {
    outline: none;
  }
`;

const STEP = 100;
const MIN = 0;
const MAX = 30000;

const RangePicker = ({ min, max, step, reset }) => {
  const [values, setValues] = useState([min || MIN, max || MAX]);
  const dispatch = useDispatch();

  const handleChange = val => {
    setValues(val);
    dispatch({ type: AppTypes.SET_VAGAS_LOADING });
    dispatch(vagasActions.debouncedRange(val));
  };

  useEffect(() => {
    if (reset) {
      setValues([MIN, MAX]);
    }
  }, [reset]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Range
        values={values}
        step={step || STEP}
        min={MIN}
        max={MAX}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#048ba8', '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <Thumb
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={props.style}
          >
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#048ba8' : '#CCC',
              }}
            />
          </Thumb>
        )}
      />
      <output style={{ marginTop: '0px' }} id="output">
        {`R$ ${values[0].toFixed(2)}`} - {`R$ ${values[1].toFixed(2)}`}
      </output>
    </div>
  );
};

export default RangePicker;
