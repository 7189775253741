import React from 'react';
import styled from 'styled-components';
import { Fields, IFilter } from 'utils/filtros';
import { useDispatch } from 'react-redux';
import { EmpresasActions } from 'store/ducks';
import { Search as SearchIcon } from 'react-feather';
import _ from 'lodash';

const SearchInput = styled.div`
  border-radius: 35px;
  width: 80%;
  height: 40px;

  & > svg {
    color: #548bf4;
  }

  & > input {
    font-family: 'Avenir Next Bold';
    color: gray;
    text-align: center;
    border: none;
    &:focus {
      outline: none;
    }
  }
`;

const SearchEmpresa = () => {
  const dispatch = useDispatch();

  const debouncedSearch = _.debounce(value => {
    dispatch(EmpresasActions.addFiltro(IFilter.icontains(Fields.nomeFantasia, value)));
  }, 1000);

  const handleChange = e => {
    const { value } = e.target;
    debouncedSearch(value);
  };

  return (
    <SearchInput className="d-flex justify-content-between align-items-center bg-white px-3 w-75 mx-auto">
      <input className="w-100" placeholder="Buscar Empresas" onChange={handleChange} />
      <SearchIcon />
    </SearchInput>
  );
};

export default SearchEmpresa;
