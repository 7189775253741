/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlimNav, Header, SubHeader } from 'components';
import { Container } from 'react-bootstrap';
import { curriculoActions } from 'store/ducks';
import { Topico } from './components/Topico';

const Curriculo = ({ inModal }) => {
  const dispatch = useDispatch();
  const curriculo = useSelector((state) => state.curriculo);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    dispatch(curriculoActions.pegarTopico());
  }, []);

  const stringToArray = (string) =>
    string &&
    string
      .split('| ')
      .map((s) => (s.endsWith(' ') ? s.substr(0, s.length - 1) : s))
      .filter((s) => s !== '');

  useEffect(() => {
    setTopics(
      curriculo
        ? [
            {
              label: 'Experiência Profissional',
              array: stringToArray(curriculo.experiencia_profissional),
              name: 'experiencia_profissional',
            },
            {
              label: 'Formação',
              array: stringToArray(curriculo.formacao),
              name: 'formacao',
            },
            {
              label: 'Idiomas',
              array: stringToArray(curriculo.idiomas),
              name: 'idiomas',
            },
            {
              label: 'Qualificações',
              array: stringToArray(curriculo.qualificacoes),
              name: 'qualificacoes',
            },
            {
              label: 'Informações Adicionais',
              array: stringToArray(curriculo.informacoes_adicionais),
              name: 'informacoes_adicionais',
            },
            {
              label: 'Objetivos',
              array: stringToArray(curriculo.objetivos),
              name: 'objetivo',
            },
          ]
        : []
    );
  }, [curriculo]);

  return (
    <>
      {!inModal && (
        <>
          <SlimNav />
          <Header />
          <SubHeader title="MEU CURRÍCULO" />
        </>
      )}
      <Container className="mb-4">
        {topics.length &&
          // eslint-disable-next-line react/no-array-index-key
          topics.map((t, i) => (
            <Topico key={i} topic={t.label} array={t.array} name={t.name} />
          ))}
      </Container>
    </>
  );
};

export default Curriculo;
