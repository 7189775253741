import { Card, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  padding-top: 15px;
  display: flex;
  background: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 7px #efe7e7;
  margin-top: 15px;
  p {
    padding: 0;
    margin: 0;
    color: #373a3c;
    font-size: 14.5px;
  }

  input.wid_board:focus {
    outline: none;
    box-shadow: none;
  }

  span {
    color: #12793a;
    font-size: 15px;

    &.gold_star {
      color: #c29100;
      padding-left: 5px;
    }
  }
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
  margin: 0px 25px;
  padding: 0px;
  background: #fff;
  color: #102542;
`;

export const CardTitle = styled(Card.Title)`
  color: #102542;
  margin: 5px 0px;
  font-weight: bold;
  text-overflow: ellipsis;
  font-family: 'Avenir Next';
  font-size: 1.1rem;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
`;

export const CardInput = styled.input`
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
`;

export const CardLabel = styled.label`
  font-size: 14px;
  color: #0a7799;
`;

export const CardButton = styled.button`
  border-radius: 5px;
  font-size: 15px;
  padding: 4px 10px;
  background-color: white;
  border-color: #0a7799;
  color: #0a7799;
  text-decoration: none;
  &:hover {
    background-color: #0a7799;
    text-decoration: none;
    color: white;
  }
`;

export const CardItem = styled.div`
  label 
`;

export const CustomButton = styled(Button)`
  color: ${(props) => props.theme.color};
  border: none;
  &:hover {
    background: none;
    color: ${(props) => props.theme.color};
  }
  svg {
    path {
      transform: translate(2px, 2px);
    }
    &:hover {
      box-shadow: 0px 0px 10px ${(props) => props.theme.color};
      border-radius: 50px;
      transition: box-shadow 0.5s;
      color: ${(props) => props.theme.color};
    }
  }
  &:focus,
  &:active {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
`;
