import React from 'react';
import Skeleton from 'react-loading-skeleton';

const VagaSkeleton = () => {
  return (
    <div style={{ padding: 25 }}>
      <div style={{ width: '40%' }}>
        <Skeleton />
      </div>
      <Skeleton count={4} />
    </div>
  );
};

export default VagaSkeleton;
