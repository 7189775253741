/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */

export const localPadrao = {
  localidade: 'São Gonçalo do Amarante',
  estado: 'RN',
};

export const filterObj = (obj, id = 0) => {
  if (!obj) return [];
  const value = Object.entries(obj).filter(item => item[0] === id);
  return value[0][1];
};

// transform an object into array to use with 'react-select/Select'
export const objToArray = obj => {
  if (!obj) return [];
  return Object.entries(obj).map(at => ({
    value: at[0],
    label: at[1],
  }));
};

// remove any caracter that's not a digit
export const stringToNumber = string => {
  return string && string.replace(/\D/g, '');
};

// transform string in cep, if possible
export const cepLead = cep => {
  if (cep) {
    const cepTemp = stringToNumber(cep);
    return `${cepTemp.slice(0, 2)}${cepTemp.length > 2 ? '.' : ''}${cepTemp.slice(2, 5)}${
      cepTemp.length > 5 ? '-' : ''
    }${cepTemp.slice(5)}`.slice(0, 10);
  }
  return null;
};

// transform string in phone number (landline or not), if possible
export const verifyPhoneNumber = (number, fixo = false) => {
  const numberT = stringToNumber(number);
  if (numberT) {
    const result = !fixo
      ? `${numberT.slice(2, 3)}${numberT.length >= 4 ? ' ' : ''}${numberT.slice(3, 7)}${
          numberT.length >= 8 ? '-' : ''
        }${numberT.slice(7)}`
      : `${numberT.slice(2, 6)}${numberT.length >= 7 ? '-' : ''}${numberT.slice(6)}`;

    return `${numberT.length >= 1 ? '(' : ''}${numberT.slice(0, 2)}${
      numberT.length >= 3 ? ') ' : ''
    }${result}`.slice(0, !fixo ? 16 : 14);
  }
  return null;
};

export const stripHtmlTags = str => {
  if (str === null || str === '') return false;
  return str.toString().replace(/<[^>]*>/g, '');
};

export const abbreviateName = name => {
  if (name && typeof name === 'string') {
    const array = name.split(' ');
    return array.length > 1 ? `${array[0]} ${array[1].substr(0, 1)}` : array[0];
  }
  return null;
};

export const DEMO = {
  BLANK_LINK: '#!',
};
