/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import { Select } from 'components';
import { components } from 'react-select';
import { objToArray } from 'utils/constantes';
import { perfilActions } from 'store/ducks';
import { useToasts } from 'react-toast-notifications';
import { CardItem, CardButton, CardInput, CardLabel } from '../styles';

const PessoaForm = ({ pessoais }) => {
  const { register, handleSubmit, errors } = useForm();
  const constantes = useSelector(state => state.constantes);
  const { addToast } = useToasts();

  const [areasAtuacaoOptions, setAreasAtuacaoOptions] = useState([]);
  const [escolaridadeOptions, setEscolaridadeOptions] = useState([]);
  const [estadoCivilOptions, setEstadoCivilOptions] = useState([]);
  const [selectedOptionAreasAtuacao, setSelectedOptionAreasAtuacao] = useState(null);
  const [selectedOptionEscolaridade, setSelectedOptionEscolaridade] = useState(null);
  const [selectedOptionEstadoCivil, setSelectedOptionEstadoCivil] = useState(null);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [sexo, setSexo] = useState(null);

  const dispatch = useDispatch();

  const onSubmit = data => {
    if (data) {
      data.areas_de_atuacao = selectedOptionAreasAtuacao
        ? selectedOptionAreasAtuacao.map(a => a.value.toString())
        : ['1'];
      data.estado_civil = selectedOptionEstadoCivil ? selectedOptionEstadoCivil.value : '0';
      data.escolaridade = selectedOptionEscolaridade ? selectedOptionEscolaridade.value : '0';
      dispatch(perfilActions.atualizarPerfil(data));
      addToast('Dados salvos!', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  };

  const handleChangeAreasAtuacao = selectedOpt => {
    setSelectedOptionAreasAtuacao(selectedOpt);
  };

  const handleChangeEscolaridade = selectedOpt => {
    setSelectedOptionEscolaridade(selectedOpt);
  };

  const handleChangeEstadoCivil = selectedOpt => {
    setSelectedOptionEstadoCivil(selectedOpt);
  };

  const handleChangeName = event => {
    setNomeCompleto(event.target.value);
  };

  const handleChangeDtNascimento = event => {
    setDataNascimento(event.target.value);
  };

  const handleSexo = event => {
    setSexo(event.target.value);
  };

  const Option = props => (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const MultiValue = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  useEffect(() => {
    setAreasAtuacaoOptions(objToArray(constantes.areas_de_atuacao));
    setEscolaridadeOptions(objToArray(constantes.nivel_de_escolaridade));
    setEstadoCivilOptions(objToArray(constantes.estado_civil));
  }, [constantes]);

  useEffect(() => {
    dispatch(perfilActions.pegarPefil());
  }, [dispatch]);

  useEffect(() => {
    if (pessoais && constantes) {
      setNomeCompleto(pessoais.nome_completo);
      setDataNascimento(pessoais.data_de_nascimento);
      setSexo(pessoais.sexo);
      setSelectedOptionEstadoCivil(
        constantes.estado_civil && {
          value: pessoais.estado_civil,
          label: constantes.estado_civil[pessoais.estado_civil],
        }
      );
      setSelectedOptionEscolaridade(
        constantes.nivel_de_escolaridade && {
          value: pessoais.escolaridade,
          label: constantes.nivel_de_escolaridade[pessoais.escolaridade],
        }
      );
      setSelectedOptionAreasAtuacao(
        constantes.areas_de_atuacao &&
          pessoais.areas_de_atuacao.map(a => ({
            value: a,
            label: constantes.areas_de_atuacao[a],
          }))
      );
    }
  }, [pessoais, constantes]);

  const animatedComponents = makeAnimated();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-100 d-grid">
      <div className="row">
        <CardItem className="col-xs-12 col-md-12">
          <div className="input-group mb-2 d-flex flex-column">
            <CardLabel className="mb-0 d-flex align-items-center" htmlFor="nome_completo">
              Nome <span className="gold_star">*</span>
            </CardLabel>
            <CardInput
              type="text"
              className="form-control w-100"
              name="nome_completo"
              ref={register({ required: 'Por favor, informe seu nome.' })}
              placeholder="Informe seu nome"
              value={nomeCompleto}
              onChange={handleChangeName}
            />
          </div>
          <div className="text-danger text-left mb-2">
            <ErrorMessage errors={errors} name="nome_completo" />
          </div>
        </CardItem>
      </div>
      <div className="row">
        <CardItem className="col-xs-12 col-md-4">
          <div className="input-group mb-2 d-flex flex-column">
            <CardLabel className="mb-0 d-flex align-items-center" htmlFor="date">
              Data de Nascimento <span className="gold_star">*</span>
            </CardLabel>
            <CardInput
              type="date"
              className="form-control w-100"
              name="data_de_nascimento"
              ref={register({
                required: 'Por favor, informe sua data de nascimento.',
              })}
              value={dataNascimento}
              onChange={handleChangeDtNascimento}
            />
          </div>
          <div className="text-danger text-left mb-2">
            <ErrorMessage errors={errors} name="data_de_nascimento" />
          </div>
        </CardItem>
        <CardItem className="col-xs-12 col-md-8 float-left">
          <CardLabel className="mb-0 pl-2 w-100 d-flex justify-content-start align-items-center">
            Sexo <span className="gold_star">*</span>
          </CardLabel>
          <div className="input-group mb-2 d-flex align-itens-center">
            <label className="mb-0 d-flex align-items-center px-2" htmlFor="feminino">
              Feminino
            </label>
            <CardInput
              id="feminino"
              type="radio"
              className="form-control wid_board"
              name="sexo"
              value="2"
              checked={sexo === '2'}
              onChange={handleSexo}
              ref={register({ required: 'Por favor, informe seu sexo.' })}
            />
            <label className="mb-0 d-flex align-items-center px-2" htmlFor="masculino">
              Masculino
            </label>
            <CardInput
              id="masculino"
              type="radio"
              className="form-control wid_board"
              name="sexo"
              value="1"
              checked={sexo === '1'}
              onChange={handleSexo}
              ref={register({ required: 'Por favor, informe seu sexo.' })}
            />
            <label className="mb-0 d-flex align-items-center px-2" htmlFor="outro">
              Outro
            </label>
            <CardInput
              id="outro"
              type="radio"
              className="form-control wid_board"
              name="sexo"
              value="0"
              checked={sexo === '0'}
              onChange={handleSexo}
              ref={register({ required: 'Por favor, informe seu sexo.' })}
            />
          </div>
          <div className="text-danger text-left mb-2">
            <ErrorMessage errors={errors} name="sexo" />
          </div>
        </CardItem>
      </div>
      <div className="row">
        <CardItem className="col-xs-12 col-md-6 float-left">
          <div className="input-group mb-2 d-flex flex-column">
            <CardLabel className="mb-0">Estado Civil</CardLabel>
            <Select
              options={estadoCivilOptions}
              closeMenuOnSelect
              hideSelectedOptions={false}
              onChange={handleChangeEstadoCivil}
              value={selectedOptionEstadoCivil}
              name="estado_civil"
              placeholder="Selecione..."
              ref={register({
                required: 'Por favor, informe seu estado civil.',
              })}
            />
          </div>
          <div className="text-danger text-left mb-2">
            <ErrorMessage errors={errors} name="estado_civil" />
          </div>
        </CardItem>
        <CardItem className="col-xs-12 col-md-6 float-left">
          <div className="input-group mb-2 d-flex flex-column">
            <CardLabel className="mb-0">Escolaridade</CardLabel>
            <Select
              options={escolaridadeOptions}
              closeMenuOnSelect
              hideSelectedOptions={false}
              onChange={handleChangeEscolaridade}
              value={selectedOptionEscolaridade}
              name="escolaridade"
              placeholder="Selecione..."
              ref={register({
                required: 'Por favor, informe sua escolaridade.',
              })}
            />
          </div>
          <div className="text-danger text-left mb-2">
            <ErrorMessage errors={errors} name="escolaridade" />
          </div>
        </CardItem>
        <CardItem className="col-xs-12 col-md-12 float-left">
          <div className="input-group mb-2 d-flex flex-column">
            <CardLabel className="mb-0">Áreas de Atuação</CardLabel>
            <Select
              options={areasAtuacaoOptions}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChangeAreasAtuacao}
              value={selectedOptionAreasAtuacao}
              name="areas_de_atuacao"
              placeholder="Selecione..."
            />
          </div>
          <div className="text-danger text-left mb-2">
            <ErrorMessage errors={errors} name="areas_de_atuacao" />
          </div>
        </CardItem>
      </div>

      <CardItem className="col-xs-12 d-flex justify-content-end">
        <CardButton className="btn btn-primary shadow-2 mb-2 mt-2" type="submit">
          Atualizar
        </CardButton>
      </CardItem>
    </form>
  );
};

export default PessoaForm;
