import { takeLatest, call, put, select, take, all, delay } from 'redux-saga/effects';
import { listarVagas, filtrarVagas, submeterCV } from 'services/vagas';
import buildQuery, { IFilter, Fields } from 'utils/filtros';
import { Types } from '../ducks/vagas';
import { Types as AppTypes } from '../ducks/app';

function* listarVagasAsync({ payload }) {
  try {
    const filtros = yield select(state => state.vagas.filtros);
    const query = filtros.join('&');
    yield put({ type: AppTypes.SET_VAGAS_LOADING });
    const { data } = yield call(listarVagas, payload, query);
    const buildPayload = {
      data: data.data.vagas,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: Types.sync.LISTAR, payload: buildPayload });
    yield put({ type: AppTypes.SET_VAGAS_LOADING_FiNISHED });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* submeterCVAsync({ payload }) {
  try {
    const { data } = yield call(submeterCV, payload.data, payload.param);
    yield put({ type: Types.sync.SUBMETER_CV, payload: data });
    yield put({ type: Types.sync.SET_IS_CANDIDATO, payload: true });
  } catch (e) {
    const msgError = e.response.data && e.response.data.message;
    yield put({ type: Types.sync.SET_ERROR, payload: msgError });
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* filtrarVagasAsync() {
  try {
    const filtros = yield select(state => state.vagas.filtros);
    const query = filtros.join('&');
    yield put({ type: AppTypes.SET_VAGAS_LOADING });

    const { data } = yield call(filtrarVagas, query);
    const buildPayload = {
      data: data.data.vagas,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: Types.sync.FILTRAR, payload: buildPayload });
    yield put({ type: AppTypes.SET_VAGAS_LOADING_FiNISHED });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* debouncedRange({ payload: val }) {
  yield delay(2000);
  const query = buildQuery(IFilter.range(Fields.salario, val[0], val[1]));
  yield put({ type: Types.sync.ADD_FILTRO, payload: query });
  yield put({ type: AppTypes.SET_VAGAS_LOADING_FiNISHED });
}

function* watchRemoveFiltro() {
  while (true) {
    yield take(Types.sync.REMOVE_FILTRO);
    yield call(filtrarVagasAsync);
  }
}

function* watchAddFiltro() {
  while (true) {
    yield take(Types.sync.ADD_FILTRO);
    yield call(filtrarVagasAsync);
  }
}

function* vagasSaga() {
  yield takeLatest(Types.async.LISTAR, listarVagasAsync);
  yield takeLatest(Types.async.SUBMETER_CV, submeterCVAsync);
  yield takeLatest(Types.async.DEBOUNCED_RANGE, debouncedRange);
  yield all([watchAddFiltro(), watchRemoveFiltro()]);
}

export default vagasSaga;
