import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  padding-top: 15px;
  display: flex;
  background: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 7px #efe7e7;
  margin-top: 15px;
  p {
    padding: 0;
    margin: 0;
    color: #373a3c;
    font-size: 14.5px;
  }

  input.wid_board:focus {
    outline: none;
    box-shadow: none;
  }

  span {
    color: #12793a;
    font-size: 15px;

    &.gold_star {
      color: #c29100;
      padding-left: 5px;
    }
  }
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  align-items: center;
  margin: 0px 25px;
  border-bottom: 1px solid gray;
  padding: 0px;
  background: #fff;
  color: #102542;
`;

export const CardTitle = styled(Card.Title)`
  color: #102542;
  font-weight: bold;
  margin: 5px;
  text-overflow: ellipsis;
  max-width: 70%;
  font-family: 'Avenir Next';
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
`;

export const CardInput = styled.input`
  margin-top: 5px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
`;

export const CardLabel = styled.label`
  font-size: 14px;
  color: #102542;
  font-weight: bold;
`;

export const CardButton = styled.button`
  border-radius: 5px;
  font-size: 15px;
  padding: 4px 10px;
  background-color: white;
  border-color: #24c324;
  color: #24c324;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  font-weight: bold;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
  &:hover,
  &:active {
    transition: background 0.3s, color 0.3s;
    border-color: #24c324;
    background-color: #24c324;
    text-decoration: none;
    color: white;
  }
`;

export const CardItem = styled.div`
  label 
`;
