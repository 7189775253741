/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ListGroup, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { CardVaga } from 'components';
import _ from 'lodash';
import Fade from 'react-bootstrap/Fade';
import './styles.scss';

const StyledList = styled(ListGroup)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ListItem = styled(ListGroup.Item)`
  border: none;
  background: transparent;
`;

const Dot = styled.div`
  background: #eec643;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 25px;
  position: relative;
  bottom: 2px;
`;

// eslint-disable-next-line react/prop-types
const ListaVagasAbertas = ({ vagas, dadosEmpresa }) => {
  const [list, setList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [skip, setSkip] = useState(5);

  useEffect(() => {
    setList(vagas.slice(0, 5));
  }, [vagas]);

  const handleChange = ({ selected }) => {
    const begin = selected * skip;
    let end = begin + skip;

    if (end > vagas.length) {
      end = vagas.length;
    }
    setList(vagas.slice(begin, end));
  };

  const makeEllipsis = () => (
    <div>
      <Dot style={{ marginRight: 5 }} />
      <Dot />
    </div>
  );

  return (
    <StyledList>
      <Fade in appear timeout={3000}>
        <>
          {_.isEmpty(list) ? (
            <div>
              <p className="text-center text-danger py-5 my-5">Nenhuma vaga encontrada</p>
            </div>
          ) : (
            list.map(vaga => (
              <ListItem key={vaga.id}>
                <CardVaga vaga={vaga} dadosEmpresa={dadosEmpresa} />
              </ListItem>
            ))
          )}
        </>
      </Fade>
      {!_.isEmpty(vagas) && (
        <Container>
          <div className="d-flex justify-content-center p-4">
            <ReactPaginate
              containerClassName="pagination"
              pageClassName="list-item"
              activeClassName="active"
              activeLinkClassName="active-item"
              pageLinkClassName="item"
              previousLabel={<ChevronLeft size={35} color="#102542" />}
              nextLabel={<ChevronRight size={35} color="#102542" />}
              breakLabel={makeEllipsis()}
              pageCount={vagas.length / skip}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={handleChange}
            />
          </div>
        </Container>
      )}
    </StyledList>
  );
};

export default ListaVagasAbertas;
