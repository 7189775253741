import React, { useEffect } from 'react';
import ImageUploader from 'react-images-upload';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { perfilActions } from 'store/ducks';

import { SlimNav, Header, SubHeader } from 'components';
import CardPerfil from './components/CardPerfil/CardPerfil';
import PessoaForm from './components/PessoaForm/PessoaForm';
import EnderecoForm from './components/EnderecoForm/EnderecoForm';
import ContatoForm from './components/ContatoForm/ContatoForm';

const PerfilPage = () => {
  const perfil = useSelector(state => state.perfil);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(perfilActions.pegarPefil());
  }, []);

  const onDrop = p => {
    if (p.length > 0) {
      // eslint-disable-next-line no-undef
      const data = new FormData();
      data.append('foto', p[0]);
      dispatch(perfilActions.atualizarFoto(data));
    }
  };

  return (
    <>
      {perfil && (
        <>
          <SlimNav />
          <Header />
          <SubHeader title="MEU PERFIL" />
          <Container className="mb-4">
            <ImageUploader
              withIcon={false}
              withPreview
              singleImage
              label="tamanho máximo: 1MB ( jpg, png, jpeg )"
              buttonText="Escolha Imagem"
              onChange={onDrop}
              imgExtension={['.jpg', '.png', '.jpeg']}
              maxFileSize={1000000}
              errorStyle={{ textAlign: 'center' }}
              fileSizeError="deve ter tamanho inferior a 1MB"
            />
            <CardPerfil titulo="Pessoal">
              <PessoaForm pessoais={perfil.pessoais} />
            </CardPerfil>
            <CardPerfil titulo="Endereço">
              <EnderecoForm endereco={perfil.endereco} />
            </CardPerfil>
            <CardPerfil titulo="Contato">
              <ContatoForm contato={perfil.contato} />
            </CardPerfil>
          </Container>
        </>
      )}
    </>
  );
};

export default PerfilPage;
