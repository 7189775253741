/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SlimNav, Header, SubHeader } from 'components';
import { Row, Col, Container } from 'react-bootstrap';
import api from 'config/api';
import styled from 'styled-components';
import _ from 'lodash';
import { stripHtmlTags } from 'utils';
import MapContainer from 'components/MapContainer';
import * as ELG from 'esri-leaflet-geocoder';
import { ListaVagasAbertas } from './components';

const Descricao = styled.div`
  padding-bottom: 25px;
  font-size: 16px;
`;

const Title = styled.h6`
  font-weight: 600;
  font-size: ${props => props.size || '16px'};
  display: block;
`;

const EmpresaInfo = styled.div`
  padding: 10px 0px;
  h2 {
    margin: 0;
    color: #007295;
    font-size: 20px;
    font-family: Bebas Neue;
  }
  p {
    color: #707070;
    font-size: 12px;
    margin: 0;
  }
`;

const Endereco = styled.address`
  p {
    margin: 0px;
    font-size: 14px;
  }
`;

const Contatos = styled.div`
  p {
    margin: 0px;
    font-size: 14px;
  }
`;

const EmpresaDetalhe = ({ match }) => {
  const [empresa, setEmpresa] = useState({});
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    const fetchVaga = async () => {
      const res = await api.get(`/empresas/publico/${match.params.id}`);
      // console.log(res.data.data.empresa);
      setEmpresa(res.data.data.empresa);
    };
    fetchVaga();
  }, [match.params.id]);

  useEffect(() => {
    if (empresa && Object.keys(empresa).length) {
      const { endereco = {} } = empresa;
      new ELG.Geocode()
        .text(
          `${endereco.logradouro} ${endereco.numero}, ${endereco.bairro}, ${endereco.cidade}, ${endereco.estado}`
        )
        // eslint-disable-next-line no-unused-vars
        .run((err, { results }, response) => {
          if (err) {
            return;
          }
          if (results && results.length) {
            setMarker(results[0].latlng);
          }
        });
    }
  }, [empresa]);

  return (
    <>
      <SlimNav />
      <Header />
      <SubHeader height="auto">
        <Container>
          <Row>
            <Col>
              <EmpresaInfo>
                <h2>{empresa.nome_fantasia}</h2>
                <p>
                  <strong>cnpj: </strong>
                  {empresa.cnpj}
                </p>
              </EmpresaInfo>
            </Col>
          </Row>
        </Container>
      </SubHeader>
      <Container>
        <Row className="pt-5" style={{ color: '#102542' }}>
          <Col>
            {empresa.descricao && (
              <Descricao>
                <p>{stripHtmlTags(empresa.descricao)}</p>
              </Descricao>
            )}
            {!_.isEmpty(empresa) && (
              <>
                <Contatos className="mb-3">
                  <Title>Contatos</Title>
                  <p>
                    <strong>tel.:&emsp;&ensp;</strong> {empresa.telefone}
                  </p>
                  <p>
                    <strong>email:&ensp;</strong> {empresa.email}
                  </p>
                </Contatos>
                <Endereco>
                  <Title>Endereço</Title>
                  <p>
                    {empresa.endereco.logradouro}, Nº: {empresa.endereco.numero}
                  </p>
                  <p>
                    {empresa.endereco.bairro} - {empresa.endereco.cidade}
                    {' / '}
                    {empresa.endereco.estado}
                  </p>
                </Endereco>
              </>
            )}
            <MapContainer marker={marker} zoom={16} />
          </Col>
          <Col>
            <Title size="17px" className="text-center">
              Vagas Disponíveis na {empresa.nome_fantasia}
            </Title>
            <ListaVagasAbertas
              dadosEmpresa={{ nome: empresa.nome_fantasia, logo: empresa.thumbnail }}
              vagas={empresa.vagas_em_aberto || []}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmpresaDetalhe;
