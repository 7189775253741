import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ListGroup, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { CardVaga } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { vagasActions } from 'store/ducks';
import _ from 'lodash';
import Fade from 'react-bootstrap/Fade';
import VagaSkeleton from './components/VagaSkeleton';
import './styles.scss';

const StyledList = styled(ListGroup)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ListItem = styled(ListGroup.Item)`
  border: none;
  background: transparent;
`;

const Dot = styled.div`
  background: #eec643;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 25px;
  position: relative;
  bottom: 2px;
`;

const ListaVagas = () => {
  const dispatch = useDispatch();
  const vagas = useSelector(state => state.vagas.data);
  const filtros = useSelector(state => state.vagas.filtros);
  const count = useSelector(state => state.vagas.count);

  const vagasLoader = useSelector(state => state.app.vagas.loading);

  useEffect(() => {
    dispatch(vagasActions.listarVagas());
  }, [dispatch, filtros]);

  const handleChange = ({ selected }) => {
    dispatch(vagasActions.listarVagas(selected + 1));
  };

  const makeEllipsis = () => (
    <div>
      <Dot style={{ marginRight: 5 }} />
      <Dot />
    </div>
  );

  return (
    <StyledList>
      <Fade in appear timeout={3000}>
        {!vagasLoader ? (
          <>
            {_.isEmpty(vagas) && !vagasLoader ? (
              <div>
                <p
                  style={{
                    margin: '35px 0px',
                    fontFamily: 'Avenir Next',
                    fontWeight: 500,
                  }}
                  className="text-center"
                >
                  Nenhuma vaga encontrada
                </p>
              </div>
            ) : (
              vagas.map((vaga, i) => (
                <ListItem className={`px-0 ${!i && 'pt-4'}`} key={vaga.id}>
                  <CardVaga vaga={vaga} />
                </ListItem>
              ))
            )}
          </>
        ) : (
          <>
            <VagaSkeleton />
            <VagaSkeleton />
            <VagaSkeleton />
          </>
        )}
      </Fade>
      {!_.isEmpty(vagas) && (
        <Container>
          <div className="d-flex justify-content-center p-4">
            <ReactPaginate
              containerClassName="pagination"
              pageClassName="list-item"
              activeClassName="active"
              activeLinkClassName="active-item"
              pageLinkClassName="item"
              previousLabel={<ChevronLeft size={35} color="#102542" />}
              nextLabel={<ChevronRight size={35} color="#102542" />}
              breakLabel={makeEllipsis()}
              pageCount={count / 15}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={handleChange}
            />
          </div>
        </Container>
      )}
    </StyledList>
  );
};

export default ListaVagas;
