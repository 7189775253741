import { takeEvery, call, put } from 'redux-saga/effects';
import { buscarConstantes } from 'services/app';
import { Types as ConstantesTypes } from '../ducks/constantes';
import { Types as AppTypes } from '../ducks/app';

function* buscarConstantesAsync() {
  try {
    const constantes = yield call(buscarConstantes);
    const { data } = constantes.data;
    yield put({ type: ConstantesTypes.sync.LISTAR, payload: data });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* appSagas() {
  yield takeEvery(ConstantesTypes.async.BUSCAR, buscarConstantesAsync);
}

export default appSagas;
