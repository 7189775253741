/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Check, Plus, Trash } from 'react-bootstrap-icons';
import { curriculoActions } from 'store/ducks';
import { useToasts } from 'react-toast-notifications';
import { CardHeader, CardTitle, StyledCard, CustomButton } from '../styles';

const Topico = ({ topic, array, name }) => {
  const [inputs, setInputs] = useState([]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleChangeIpt = (event, i) => {
    if (inputs.length && event) {
      const newInputs = [...inputs];
      newInputs[i] = event.target && event.target.value;
      setInputs(newInputs);
    }
  };

  const handleChangeRemove = (event, i) => {
    if (inputs.length > i && event) {
      const newInputs = [...inputs];
      newInputs.splice(i, 1);
      setInputs(newInputs);
    }
  };

  const handleChangeAdd = () => {
    if (inputs) {
      const newInputs = [...inputs];
      newInputs.push('');
      setInputs(newInputs);
    }
  };

  const handleChangeBtnSend = () => {
    const data = {
      [name]: `| ${inputs.join(' | ')}`,
    };
    dispatch(curriculoActions.atualizarTopico(data));
    addToast(`Dados do campo '${topic}' salvos!`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  useEffect(() => {
    setInputs(array && array.length ? array : ['']);
  }, [array]);

  return (
    <StyledCard>
      <CardHeader text="white">
        <CardTitle>{topic}</CardTitle>
      </CardHeader>
      <StyledCard.Body className="d-flex justify-content-between flex-column">
        <ul className="w-100 pl-0">
          {inputs &&
            inputs.map((ipt, i) => (
              <div // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="input-group mb-2 d-flex w-100"
              >
                <input
                  className="form-control rounded"
                  placeholder="Insira a informação"
                  value={ipt}
                  onChange={(event) => handleChangeIpt(event, i)}
                />
                <CustomButton
                  variant="outline-danger"
                  size="sm"
                  className="trash"
                  theme={{ color: '#dc3545' }}
                  onClick={(event) => handleChangeRemove(event, i)}
                >
                  <Trash width="32" height="32" />
                </CustomButton>
              </div>
            ))}
        </ul>
        <div className="align-self-end">
          <CustomButton
            variant="outline-info"
            size="sm"
            theme={{ color: '#28a745' }}
            onClick={handleChangeAdd}
          >
            <Plus width="32" height="32" />
          </CustomButton>
          <CustomButton
            variant="outline-info"
            size="sm"
            onClick={handleChangeBtnSend}
            theme={{ color: '#0a7799' }}
          >
            <Check width="32" height="32" />
          </CustomButton>
        </div>
      </StyledCard.Body>
    </StyledCard>
  );
};

export default Topico;
