/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, ErrorMessage } from 'react-hook-form';
import { authActions } from 'store/ducks';
import { setUpAfterLogin } from 'services/auth';
import api from 'config/api';
import { ChevronRight, ChevronLeft } from 'react-feather';
import logoemprega from 'assets/images/logoemprega.png';
import { Link } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';
import { AuthWrapper, Input, Button } from '../styles';

// eslint-disable-next-line react/prop-types
const SignUp = ({ history }) => {
  const { register, handleSubmit, triggerValidation, getValues, errors, formState } = useForm();
  const [step, setStep] = useState('name_email');
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const showError = msg => {
    setError(msg);

    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const handleReturn = () => {
    if (step !== 'name_email') {
      setStep('name_email');
    }
  };

  const onSubmit = data => {
    if (step === 'name_email') {
      api
        .post('/register', data)
        .then(() => {
          setStep('password_token');
        })
        .catch(e => {
          if (e.response) {
            showError(e.response.data && e.response.data.message);
          }
        });
    } else {
      api
        .post('/register/validation', data)
        .then(response => {
          setUpAfterLogin(response.data.data);
          dispatch(authActions.register(response.data.data));
        })
        .catch(e => {
          if (e.response) {
            showError(e.response.data && e.response.data.message);
          }
        });
    }
  };

  const repeatVal = senhaConfirmacao =>
    senhaConfirmacao === getValues().senha || 'Essa senha deve ser igual a anterior!';

  const minLengthIpt = senha =>
    (senha && senha.length >= 6) || 'A senha deve conter no mínimo 6 dígitos';

  const validateRepeat = () => {
    if (formState.isSubmitted) {
      triggerValidation({ name: 'senhaConfirmacao' });
    }
  };

  useEffect(() => {
    if (auth.token) {
      history.push('/');
    }
  }, [auth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (localStorage.getItem('user_token')) {
      // eslint-disable-next-line react/prop-types
      history.push('/');
    }
    // eslint-disable-next-line no-undef
  }, [localStorage.getItem('user_token')]);

  // eslint-disable-next-line no-undef
  if (localStorage.getItem('user_token')) {
    return null;
  }

  return (
    <AuthWrapper className="m-0 text-white d-flex flex-column justify-content-around align-items-center w-100">
      <Button
        type="submit"
        style={{ position: 'absolute', top: 20, right: 20, color: '#102542' }}
        onClick={() => history.goBack()}
        className="btn px-3 d-flex justify-content-center align-items-center"
      >
        <TiArrowBack size={30} />
        <span style={{ transform: 'translate(0px, 3px)' }}>Voltar</span>
      </Button>
      <div className="card bg-transparent border-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-body text-center py-0">
            <div className="mb-4">
              <i className="feather icon-user-plus auth-icon" />
            </div>
            <h4 className="mb-0 font-weight-bold title">
              {step === 'password_token' ? 'Finalizar ' : ''}Cadastro
            </h4>
            {step === 'name_email' && (
              <>
                <p className="small_text">
                  Complete seu cadastro para ter acesso as melhores vagas da região
                </p>
                <div className="input-group mb-2">
                  <Input
                    type="text"
                    className="form-control w-100"
                    name="nome_completo"
                    ref={register({ required: 'Informe seu nome completo' })}
                    placeholder="Nome"
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage as={<small />} errors={errors} name="name" />
                </div>
                <div className="input-group mb-2">
                  <Input
                    type="email"
                    className="form-control"
                    name="email"
                    ref={register({ required: 'Informe seu email' })}
                    placeholder="Email"
                  />
                </div>
                <div className="text-danger text-center mb-2">
                  <ErrorMessage as={<small />} errors={errors} name="email" />
                </div>
                <div>
                  <span className="mr-auto links">
                    Já possui cadastro?<Link to="/entrar"> Cique aqui para entrar</Link>
                  </span>
                </div>
              </>
            )}
            {step === 'password_token' && (
              <>
                <p className="small_text">
                  Digite o código que foi enviado para o seu email e sua senha de acesso.
                </p>
                <div className="input-group mb-2">
                  <Input
                    type="text"
                    className="form-control w-100"
                    name="token"
                    ref={register({ required: 'Informe o código recebido por email' })}
                    placeholder="Código"
                    onChange={validateRepeat}
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage as={<small />} errors={errors} name="token" />
                </div>
                <div className="input-group mb-2">
                  <Input
                    type="password"
                    className="form-control"
                    name="senha"
                    ref={register({ required: 'Informe uma nova senha', validate: minLengthIpt })}
                    placeholder="Senha"
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage as={<small />} errors={errors} name="senha" />
                </div>
                <div className="input-group mb-2">
                  <Input
                    type="password"
                    className="form-control"
                    name="senhaConfirmacao"
                    ref={register({
                      required: 'Confirme a sua senha',
                      validate: repeatVal || minLengthIpt,
                    })}
                    placeholder="Confirme a senha"
                  />
                </div>
                <div className="text-danger text-left mb-2">
                  <ErrorMessage as={<small />} errors={errors} name="senhaConfirmacao" />
                </div>
              </>
            )}
            {step !== 'name_email' && (
              <Button
                className="btn btn-secondary shadow-2 my-3 pl-1 mr-2"
                type="submit"
                onClick={handleReturn}
              >
                <ChevronLeft />
                Voltar
              </Button>
            )}
            <Button
              className={`btn btn-primary shadow-2 my-3 ${step !== 'password_token' ? 'pr-1' : ''}`}
              type="submit"
            >
              {step === 'password_token' ? 'Cadastrar' : 'Avançar'}
              {step !== 'password_token' ? <ChevronRight /> : ''}
            </Button>
            {error && (
              <div className="text-danger text-center mb-2">
                <span>{error}</span>
              </div>
            )}
          </div>
        </form>
        <div className="text-center w-100">Passo {step === 'password_token' ? '2' : '1'} de 2</div>
      </div>
      <img src={logoemprega} alt="logo do site" width={150} />
    </AuthWrapper>
  );
};

export default SignUp;
