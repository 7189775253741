import { takeLatest, call, put } from 'redux-saga/effects';
import { listarNoticias, listarNoticia } from 'services/noticias';
import { Types as NoticiasTypes } from '../ducks/noticias';
import { Types as AppTypes } from '../ducks/app';

function* pegarNoticiasAsync({ payload }) {
  try {
    const { data } = yield call(listarNoticias, payload);
    const buildPayload = {
      page: data.data.links.next
        ? parseInt(data.data.links.next.substr(data.data.links.next.lastIndexOf('=') + 1), 10)
        : '',
      data: data.data.noticias,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: NoticiasTypes.sync.PEGAR_NOTICIAS, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* pegarNoticiaAsync({ payload }) {
  try {
    const { data } = yield call(listarNoticia, payload);
    yield put({ type: NoticiasTypes.sync.PEGAR_NOTICIA, payload: data.data.noticia });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* noticiasSagas() {
  yield takeLatest(NoticiasTypes.async.PEGAR_NOTICIAS, pegarNoticiasAsync);
  yield takeLatest(NoticiasTypes.async.PEGAR_NOTICIA, pegarNoticiaAsync);
}

export default noticiasSagas;
