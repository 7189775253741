import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  right: 30px;
  top: 15px;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    background: white;
    border-radius: 25px;
    width: 100%;
    transition-duration: 0.5s;
    height: 3px;
  }

  &.on {
    transition-duration: 0.5s;
    & > .one {
      transform: rotate(45deg) translate(6px, 6px);
    }
    & > .two {
      opacity: 0;
    }
    & > .three {
      transform: rotate(-45deg) translate(6px, -6px);
    }
  }
`;

// eslint-disable-next-line react/prop-types
const MenuIcon = ({ isActive, handleClick }) => {
  return (
    <Icon className={`${isActive && 'on'}`} onClick={handleClick}>
      <div className="one" />
      <div className="two" />
      <div className="three" />
    </Icon>
  );
};

export default MenuIcon;
