/* eslint-disable no-case-declarations */
import buildQuery from 'utils/filtros';
import _ from 'lodash';

const INITIAL_STATE = {
  page: 1,
  data: [],
  filtros: [],
  links: {
    next: null,
    previous: null,
  },
  count: 0,
  submit_error: null,
  submit_success: null,
  is_candidato: null,
};

export const Types = {
  async: {
    LISTAR: 'vagas/listar_async',
    FILTRAR: 'vagas/filtrar_async',
    SUBMETER_CV: 'vagas/submeter_cv_async',
    DEBOUNCED_RANGE: 'vagas/debounced_range',
  },
  sync: {
    LISTAR: 'vagas/listar_sync',
    FILTRAR: 'vagas/filtrar_sync',
    ADD_FILTRO: 'vagas/adicionar_filtro',
    REMOVE_FILTRO: 'vagas/remover_filtro',
    REMOVE_ALL_FILTRO: 'vagas/remover_all_filtro',
    SUBMETER_CV: 'vagas/submeter_cv_sync',
    SET_ERROR: 'vagas/submeter_cv_error',
    SET_SUCCESS: 'vagas/submeter_cv_success',
    SET_IS_CANDIDATO: 'vagas/is_candidato',
  },
};

// Actions Creators
export const Actions = {
  listarVagas: (page = 1) => ({ type: Types.async.LISTAR, payload: page }),
  submeterCV: (data, param) => ({ type: Types.async.SUBMETER_CV, payload: { data, param } }),
  debouncedRange: val => ({ type: Types.async.DEBOUNCED_RANGE, payload: val }),
  filtarVagas: () => {
    return { type: Types.async.FILTRAR };
  },
  addFiltro: filtro => {
    const query = buildQuery(filtro);
    return { type: Types.sync.ADD_FILTRO, payload: query };
  },
  removeFiltro: filtro => {
    return { type: Types.sync.REMOVE_FILTRO, payload: filtro };
  },
  removeAllFiltros: () => {
    return { type: Types.sync.REMOVE_ALL_FILTRO };
  },
  submeterCVError: error => {
    return { type: Types.sync.SET_ERROR, payload: error };
  },
  submeterCVSuccess: success => {
    return { type: Types.sync.SET_SUCCESS, payload: success };
  },
  setIsCandidato: result => {
    return { type: Types.sync.SET_IS_CANDIDATO, payload: result };
  },
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.LISTAR:
      return { ...state, ...action.payload };
    case Types.sync.FILTRAR:
      return { ...state, ...action.payload };
    case Types.sync.ADD_FILTRO:
      const el = _.find(state.filtros, item =>
        item.includes(action.payload.substring(0, action.payload.indexOf('=')))
      );

      if (el) return { ...state, filtros: [..._.without(state.filtros, el), action.payload] };
      return { ...state, filtros: [...state.filtros, action.payload] };

    case Types.sync.REMOVE_FILTRO:
      return {
        ...state,
        filtros: _.without(
          state.filtros,
          state.filtros.find(item => item.includes(action.payload))
        ),
      };
    case Types.sync.REMOVE_ALL_FILTRO:
      return { ...state, filtros: [] };
    case Types.sync.SUBMETER_CV:
      return { ...state, submit_success: true };
    case Types.sync.SET_ERROR:
      return { ...state, submit_error: action.payload };
    case Types.sync.SET_SUCCESS:
      return { ...state, submit_success: action.payload };
    case Types.sync.SET_IS_CANDIDATO:
      return { ...state, is_candidato: action.payload };
    default:
      return state;
  }
};
