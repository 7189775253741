const INITIAL_STATE = {};

export const Types = {
  async: {
    BUSCAR: 'constantes/buscar_async',
  },
  sync: {
    LISTAR: 'constantes/listar',
  },
};

// Actions Creators
export const Actions = {
  buscarConstantes: () => ({ type: Types.async.BUSCAR }),
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.LISTAR:
      return action.payload;
    default:
      return state;
  }
};
