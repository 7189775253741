/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardWrap = styled.div`
  transition: box-shadow 0.4s, opacity 0.4s;
  border-radius: 10px;
  border: none;
  & > a {
    &:hover {
      text-decoration: none;
    }
    img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    div {
      & > span {
        font-family: 'Avenir Next Bold';
        color: #102542;
      }
      & > small {
        color: gray;
      }
    }
  }
  .card-body.content {
    color: black;
  }
  &:hover {
    transition: box-shadow 0.4s, opacity 0.4s;
    box-shadow: 0 0 10px grey;
    opacity: 0.8;
  }
`;

const NoticiaCard = ({ noticia }) => {
  const dt = new Date(noticia.ultima_atualizacao_em);

  return (
    <CardWrap className="card">
      <Link to={`/noticias/${noticia.id}`}>
        <img src={noticia.imagem} alt={noticia.titulo} className="card-img-top" />
        <div className="card-body p-2 pb-0 content d-flex flex-column justify-content-between align-items-center">
          <span className="card-text text-center">{noticia.titulo}</span>
          <small className="mt-3">
            Atualizada em{' '}
            {dt.toLocaleDateString('pt-BR', { month: 'long', day: 'numeric', year: 'numeric' })}
          </small>
        </div>
      </Link>
    </CardWrap>
  );
};

export default NoticiaCard;
