/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const Select = forwardRef((props, ref) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        options={[props.allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === props.allOption.value) {
              return props.onChange([props.allOption, ...props.options]);
            }
            let result = [];
            if (selected.length === props.options.length) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(option => option.value !== props.allOption.value);
              } else if (event.action === 'select-option') {
                result = [props.allOption, ...props.options];
              }
              return props.onChange(result);
            }
          }

          return props.onChange(selected);
        }}
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReactSelect {...props} />;
});

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

Select.defaultProps = {
  allOption: {
    label: 'Selecione Todos',
    value: '*',
  },
};

export default Select;
