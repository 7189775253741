/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { Fields } from 'utils/filtros';
import { vagasActions } from 'store/ducks';
import FilterItem from './components/FilterItem';
import RangePicker from './components/RangePicker';

const SideNav = styled.nav`
  position: sticky;
  top: 65px;
`;

const Label = styled.span``;

const ListItem = styled(ListGroup.Item)`
  border: none;
  background: transparent;
`;

const Title = styled.h2`
  font-size: 28px;
`;

const Header = styled.div`
  button {
    background: #c9465f;
    border: 1px solid #c9465f;
    border-radius: 25px;
    color: white;
    height: fit-content;
    font-family: 'Bebas Neue', cursive;
    transition-duration: 0.5s;
    &:focus {
      outline: none;
    }
    &:hover {
      box-shadow: 0 0 10px #c9465f;
      transition-duration: 0.5s;
    }
  }
`;

// eslint-disable-next-line react/prop-types
const Sidebar = ({ sideBarData }) => {
  const constantes = useSelector(state => state.constantes);
  const dispatch = useDispatch();

  return (
    <SideNav>
      <ListGroup>
        <ListItem>
          <Header className="d-flex justify-content-between">
            <Title style={{ fontFamily: 'Avenir Next Bold' }}>Filtros</Title>
            <button
              className="px-2"
              type="button"
              onClick={() => dispatch(vagasActions.removeAllFiltros())}
            >
              Remover
            </button>
          </Header>
        </ListItem>
        <ListItem>
          <FilterItem
            field={Fields.areasDeAtuacao}
            initialValue={sideBarData[Fields.areasDeAtuacao]}
            label="Área de atuação"
            data={constantes.areas_de_atuacao}
            multiField
          />
        </ListItem>
        <ListItem>
          <FilterItem
            field={Fields.nivelDeEscolaridade}
            initialValue={sideBarData[Fields.nivelDeEscolaridade]}
            label="Nível de escolaride"
            data={constantes.nivel_de_escolaridade}
            multiField
          />
        </ListItem>
        <ListItem>
          <FilterItem
            field={Fields.regimeDeContratacao}
            initialValue={sideBarData[Fields.regimeDeContratacao]}
            label="Regime de contratação"
            data={constantes.regime_de_contratacao}
            multiField
          />
        </ListItem>
        <ListItem>
          <Label>Salário</Label>
          {sideBarData[Fields.salario] ? (
            <RangePicker {...sideBarData[Fields.salario]} />
          ) : (
            <RangePicker reset />
          )}
        </ListItem>
      </ListGroup>
    </SideNav>
  );
};

export default Sidebar;
