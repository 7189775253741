import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Header, SubHeader } from 'components';
import { noticiasActions } from 'store/ducks';
import { NoticiaCard } from './components';

const Content = styled(Container)`
  @media (min-width: 576px) {
    .card-columns {
      column-count: 1;
    }
  }

  @media (min-width: 768px) {
    .card-columns {
      column-count: 2;
    }
  }

  @media (min-width: 992px) {
    .card-columns {
      column-count: 3;
    }
  }

  @media (min-width: 1200px) {
    .card-columns {
      column-count: 4;
    }
  }
`;

const Noticias = () => {
  const dispatch = useDispatch();
  const noticias = useSelector(state => state.noticias);
  const [items, setItems] = useState([]);

  useEffect(() => {
    dispatch(noticiasActions.pegarNoticias());
  }, [dispatch]);

  useEffect(() => {
    const news = new Set(items.concat(noticias.data));
    setItems([...news]);

    return () => {
      setItems([]);
    };
  }, [noticias.links, noticias.links.next]);

  const loadMore = () => {
    setTimeout(() => {
      dispatch(noticiasActions.pegarNoticias(noticias.page));
    }, 1000);
  };

  return (
    <>
      <Header />
      <SubHeader title="NOTÍCIAS" />
      <Content className="mt-3">
        <InfiniteScroll
          dataLength={items.length} // This is important field to render the next data next={loadMore}
          hasMore={noticias.links.next}
          hasChildren
          next={loadMore}
          initialScrollY={1}
          loader={
            <div className="text-center w-100 py-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          endMessage={
            <p className="text-center w-100 py-5" style={{ color: '#00516a' }}>
              <b>Não há mais notícias para mostrar</b>
            </p>
          }
        >
          <div className="card-columns p-2">
            {items.map((n, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <NoticiaCard key={i} noticia={n} />
            ))}
          </div>
        </InfiniteScroll>
      </Content>
    </>
  );
};

export default Noticias;
