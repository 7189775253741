import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: ${props => props.height || 'fit-content'};
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  h3 {
    color: rgb(16, 37, 66);
    font-size: 22px;
    margin-top: 4px !important;
    font-family: 'Bebas Neue', cursive;
  }
`;

// eslint-disable-next-line react/prop-types
const SubHeader = ({ height, children, title }) => {
  return (
    <Container height={height} className="d-flex justify-content-center align-items-center">
      {children || (
        <h3 id="only-title" className="text-center w-100 py-3 m-0">
          {title}
        </h3>
      )}
    </Container>
  );
};

export default SubHeader;
