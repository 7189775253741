import { takeLatest, call, put } from 'redux-saga/effects';
import {
  atualizarPerfil,
  atualizarFoto,
  atualizarEndereco,
  pegarPerfil,
  atualizarContato,
} from 'services/perfilusuario';
import { getUser, setUser } from 'utils/storage';
import { Types } from '../ducks/pessoaperfil';
import { Types as AppTypes } from '../ducks/app';

function* atualizarEnderecoAsync({ payload }) {
  try {
    const { data } = yield call(atualizarEndereco, payload);
    const buildPayload = {
      endereco: data.endereco,
    };
    yield put({ type: Types.sync.ATUALIZAR_ENDERECO, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* atualizarContatoAsync({ payload }) {
  try {
    const { data } = yield call(atualizarContato, payload);
    const buildPayload = {
      contato: data.contato,
    };
    yield put({ type: Types.sync.ATUALIZAR_CONTATO, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* atualizarPerfilAsync({ payload }) {
  try {
    const { data } = yield call(atualizarPerfil, payload);
    const buildPayload = {
      pessoas: data.pessoas,
    };
    yield put({ type: Types.sync.ATUALIZAR_DT_PESSOAIS, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* atualizarFotoAsync({ payload }) {
  try {
    const { data } = yield call(atualizarFoto, payload);
    const newUser = getUser();
    newUser.foto = data.data.candidato.pessoa.foto;
    setUser(newUser);
    yield put({
      type: AppTypes.SET_TOASTR,
      payload: { message: 'Foto atualizada com sucesso!', abled: true, status: 'success' },
    });
    yield put({ type: AppTypes.UPD_USER, payload: true });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* pegarPerfilAsync() {
  try {
    const { data } = yield call(pegarPerfil);
    const buildPayload = candidato => {
      return {
        contato: candidato.pessoa.contato,
        endereco: candidato.pessoa.endereco,
        pessoais: {
          nome_completo: candidato.pessoa.nome_completo,
          data_de_nascimento: candidato.pessoa.data_de_nascimento,
          sexo: candidato.pessoa.sexo,
          estado_civil: candidato.pessoa.estado_civil,
          escolaridade: candidato.escolaridade,
          areas_de_atuacao: candidato.areas_de_atuacao,
        },
      };
    };
    const perfil = buildPayload(data.data.candidato);
    yield put({
      type: Types.sync.PEGAR_PERFIL,
      payload: perfil,
    });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* vagasSaga() {
  yield takeLatest(Types.async.ATUALIZAR_ENDERECO, atualizarEnderecoAsync);
  yield takeLatest(Types.async.ATUALIZAR_DT_PESSOAIS, atualizarPerfilAsync);
  yield takeLatest(Types.async.ATUALIZAR_CONTATO, atualizarContatoAsync);
  yield takeLatest(Types.async.ATUALIZAR_FOTO, atualizarFotoAsync);
  yield takeLatest(Types.async.PEGAR_PERFIL, pegarPerfilAsync);
}

export default vagasSaga;
