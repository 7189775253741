import axios from 'axios';
import { appActions, authActions } from 'store/ducks';
import store from '../store';

// const BASE_URL = 'https://qt-api.connectaaplicativos.com.br/api';
const BASE_URL = 'https://qt-sg-api.connectaaplicativos.com.br/api';

const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.request.use(
  config => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('user_token');

    if (token) {
      // token =
      //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozMDgsInVzZXJuYW1lIjoiZmVseXBlLmNldC4xNUBnbWFpbC5jb20iLCJleHAiOjE1OTk5MTY1MzMsImVtYWlsIjoiZmVseXBlLmNldC4xNUBnbWFpbC5jb20ifQ.mJJeijS6NjvJnbkDbskoG9upchCrYQ6VVPErryUytic';
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    if (response.data && response.data.message) {
      store.dispatch(
        appActions.setAppToastr({
          message: response.data.message,
          status: 'success',
          abled: true,
        })
      );
    }
    return response;
  },
  error => {
    let msg = '';
    if (error.response.status === 401 && error.response.data.detail === 'Signature has expired.') {
      store.dispatch(authActions.login({ token: null, pessoa: null }));
      // eslint-disable-next-line no-undef
      localStorage.clear();
      msg = 'Sua sessão expirou! Entre novamente.';
    } else if (error.response.status / 500 === 1) {
      msg = 'Erro no servidor.';
    }
    store.dispatch(
      appActions.setAppToastr({
        message: msg,
        status: 'error',
        abled: true,
      })
    );
    return Promise.reject(error);
  }
);

export default http;
