import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SlimNav, Header, SubHeader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { EmpresasActions } from 'store/ducks';
import { EmpresaCard, SearchEmpresa } from './components';

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const CardGridContainer = styled(InfiniteScroll)`
  margin-top: 25px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const EmpresasPage = () => {
  const empresas = useSelector(state => state.empresas);
  const filtros = useSelector(state => state.empresas.filtros);
  const page = useSelector(state => state.empresas.page);

  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (empresas.data.length === 0) dispatch(EmpresasActions.listarEmpresas());
  }, []);

  useEffect(() => {
    setItems([]);
  }, [filtros]);

  useEffect(() => {
    const emp = new Set(items.concat(empresas.data));
    setItems([...emp]);

    return () => {
      setItems([]);
    };
  }, [empresas.links, empresas.links.next]);

  const loadMore = () => {
    setTimeout(() => {
      dispatch(EmpresasActions.listarEmpresas(page, filtros[0]));
    }, 1000);
  };

  return (
    <>
      <SlimNav />
      <Header>
        <SearchEmpresa />
      </Header>
      <SubHeader height="auto" title="EMPRESAS" />
      <Container>
        <CardGridContainer
          dataLength={items.length} // This is important field to render the next data next={loadMore}
          hasMore={empresas.links.next}
          hasChildren
          next={loadMore}
          initialScrollY={1}
          loader={
            <div style={{ width: '100%', padding: '25px 0px' }} className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          endMessage={
            <p
              style={{ textAlign: 'center', width: '100%', padding: '25px 0px', color: '#00516a' }}
            >
              <b>Não há mais empresas para mostrar</b>
            </p>
          }
        >
          {items.map(empresa => {
            return <EmpresaCard className="mx-0" empresa={empresa} key={empresa.id} />;
          })}
        </CardGridContainer>
      </Container>
    </>
  );
};

export default EmpresasPage;
