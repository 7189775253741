/* eslint-disable react/prop-types */
import React from 'react';
import { CardHeader, CardTitle, StyledCard } from '../styles';

const CardPerfil = ({ titulo, children }) => {
  return (
    <StyledCard>
      <CardHeader text="white">
        <CardTitle>{titulo}</CardTitle>
      </CardHeader>
      <StyledCard.Body className="d-flex justify-content-between">{children}</StyledCard.Body>
    </StyledCard>
  );
};

export default CardPerfil;
