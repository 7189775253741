import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { HomePage, VagaPage, EmpresaDetalhe, Noticias, NoticiaDetalhes } from 'pages';
import { constantesActions, appActions } from 'store/ducks';
import EmpresasPage from 'pages/Empresas';
import SignIn from 'pages/Authentication/SignIn/SignIn';
import SignUp from 'pages/Authentication/SignUp/SignUp';
import ResetPass from 'pages/Authentication/Recover/ResetPass';
import PerfilUsuario from 'pages/PerfilUsuario/index';
import Curriculo from 'pages/Curriculo';
import Candidaturas from 'pages/Candidaturas';
import { useToasts } from 'react-toast-notifications';
import { getUser } from 'utils/storage';

function App() {
  const dispatch = useDispatch();
  const toastr = useSelector(state => state.app.toastr);
  const user = useSelector(state => state.auth.user);
  const updateUser = useSelector(state => state.app.updateUser);
  const [isLogged, setIsLogged] = useState(user !== null || getUser() !== null);
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(constantesActions.buscarConstantes());
  }, [dispatch, user]);

  useEffect(() => {
    if (updateUser) {
      dispatch(appActions.updateUser(false));
    }
  }, [updateUser]);

  useEffect(() => {
    if (toastr.abled) {
      addToast(toastr.message, {
        appearance: toastr.status,
        autoDismiss: true,
      });
      dispatch(appActions.setAppToastr({ message: '', status: '', abled: false }));
    }
  }, [toastr]);

  useEffect(() => {
    setIsLogged(user !== null || getUser() !== null);
  }, [user]);

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/vaga/:id" component={isLogged ? VagaPage : SignIn} />
      <Route path="/empresas/:id" component={EmpresaDetalhe} />
      <Route path="/empresas" component={EmpresasPage} />
      <Route path="/entrar" component={SignIn} />
      <Route path="/cadastro" component={SignUp} />
      <Route path="/recuperar_senha" component={ResetPass} />
      <Route path="/perfilusuario" component={isLogged ? PerfilUsuario : SignIn} />
      <Route path="/curriculo" component={isLogged ? Curriculo : SignIn} />
      <Route path="/candidaturas" component={isLogged ? Candidaturas : SignIn} />
      <Route path="/noticias/:id" component={NoticiaDetalhes} />
      <Route path="/noticias" component={Noticias} />
    </Switch>
  );
}

export default App;
