import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noticiasActions } from 'store/ducks';
import { Header } from 'components';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import useWindowDimensions from 'components/windowDimensions';

const CardWrap = styled(Container)`
  small {
    color: #007295;
    font-size: 14px;
  }
  p#title {
    font-size: 20px;
    color: #007295;
    font-weight: bold;
    max-width: ${props => (props.is_mobile ? '400px' : '')};
  }
  img {
    max-width: 400px;
  }
`;

// eslint-disable-next-line react/prop-types
const NoticiaDetalhes = ({ match }) => {
  const dispatch = useDispatch();
  const noticia = useSelector(state => state.noticias.noticia);
  const { width } = useWindowDimensions();
  let dt = null;

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    dispatch(noticiasActions.pegarNoticia(match.params.id));
  }, []);

  if (noticia) {
    dt = new Date(noticia.ultima_atualizacao_em);
  }

  return (
    <>
      <Header />
      <CardWrap className="row no-gutters mx-auto mb-5 mt-3" is_mobile={width < 800 ? 1 : 0}>
        {noticia && (
          <>
            <small className="mt-3 mb-2 w-100 border-bottom">
              Atualizada em{' '}
              {dt &&
                dt.toLocaleDateString('pt-BR', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
            </small>
            <div
              className={`col-md-12 ${width < 800 ? 'd-flex flex-column align-items-center' : ''}`}
            >
              <img
                className="mr-3 w-100"
                src={noticia.imagem}
                alt={noticia.titulo}
                align={width > 799 ? 'left' : 'center'}
              />
              <div>
                <p id="title" className="card-text text-center w-100 mx-auto">
                  {noticia.titulo}
                </p>
                <p
                  className="text-justify mt-4"
                  dangerouslySetInnerHTML={{ __html: noticia.conteudo }}
                />
              </div>
            </div>
          </>
        )}
      </CardWrap>
    </>
  );
};

export default NoticiaDetalhes;
