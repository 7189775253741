import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ListGroup, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { candidaturasActions } from 'store/ducks';
import { SlimNav, Header, SubHeader } from 'components';
import _ from 'lodash';
import { CandidaturaCard /* , CandidaturaSearch */ } from './components';

const StyledList = styled(ListGroup)`
  justify-content: center;
  width: 100%;
`;

const ListItem = styled(ListGroup.Item)`
  border: none;
  background: transparent;
`;

const ListaCandidaturas = () => {
  const dispatch = useDispatch();
  const candidaturas = useSelector((state) => state.candidaturas.data);
  const filtros = useSelector((state) => state.candidaturas.filtros);
  const count = useSelector((state) => state.candidaturas.count);
  const candidaturasLoader = useSelector((state) => state.candidaturas.loading);

  useEffect(() => {
    dispatch(candidaturasActions.listarCandidaturas());
  }, [dispatch, filtros]);

  const handleChange = ({ selected }) => {
    dispatch(candidaturasActions.listarCandidaturas(selected + 1));
  };

  return (
    <>
      <SlimNav />
      <Header>{/* <CandidaturaSearch /> */}</Header>
      <SubHeader height="auto" title="CANDIDATURAS" />
      <StyledList className="d-grid container px-2">
        <>
          {_.isEmpty(candidaturas) && !candidaturasLoader ? (
            <div>
              <p
                style={{
                  fontFamily: 'Avenir Next',
                  fontWeight: 500,
                }}
                className="text-center m-4"
              >
                Nenhuma candidatura encontrada
              </p>
            </div>
          ) : (
            <div className="row mx-0">
              {candidaturas.map((c) => (
                <ListItem
                  key={c.id}
                  className="col-sm-12 col-md-6 col-lg-4 px-1"
                >
                  <CandidaturaCard candidatura={c} />
                </ListItem>
              ))}
            </div>
          )}
        </>
        {!_.isEmpty(candidaturas) && (
          <Container>
            <div className="d-flex justify-content-center p-4">
              <ReactPaginate
                containerClassName="pagination"
                pageClassName="list-item"
                activeClassName="active"
                activeLinkClassName="active-item"
                pageLinkClassName="item"
                previousLabel={<ChevronLeft size={35} color="#102542" />}
                nextLabel={<ChevronRight size={35} color="#102542" />}
                breakLabel="..."
                pageCount={count / 15}
                marginPagesDisplayed={5}
                pageRangeDisplayed={5}
                onPageChange={handleChange}
              />
            </div>
          </Container>
        )}
      </StyledList>
    </>
  );
};

export default ListaCandidaturas;
