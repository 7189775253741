import { all } from 'redux-saga/effects';
import vagasSaga from './vagas';
import empresasSaga from './empresas';
import appSagas from './app';
import pessoasPerfil from './pessoasperfil';
import candidaturasSaga from './candidaturas';
import noticiasSaga from './noticias';
import curriculoSaga from './curriculo';
import mensagensSaga from './mensagens';

export default function* rootSaga() {
  yield all([
    appSagas(),
    empresasSaga(),
    vagasSaga(),
    pessoasPerfil(),
    curriculoSaga(),
    candidaturasSaga(),
    noticiasSaga(),
    mensagensSaga(),
  ]);
}
