import React from 'react';
import { Jumbotron, Row, Col, Container } from 'react-bootstrap';
import bg from 'assets/images/bg.png';

import styled from 'styled-components';
import SlimNav from 'components/SlimNav';

const StyledHeader = styled(Jumbotron)`
  display: flex;
  align-items: center;
  background-image: url(${bg});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position-y: -100px;
  height: ${props => props.height || '250px'};
  margin-top: ${props => props.mt || '0px'};
  margin-bottom: 0;

  @media only screen and (max-width: 575px) {
    .logo {
      display: none;
    }
  }
`;

// eslint-disable-next-line react/prop-types
const Header = ({ height = '115px', children, hideBg }) => {
  if (hideBg) {
    return <SlimNav />;
  }
  return (
    <StyledHeader fluid height={height} mt="50px">
      <SlimNav />
      <Container>
        {children && (
          <Row className="justify-content-center">
            <Col xs={12} md={5} className="d-flex justify-content-center">
              {children}
            </Col>
          </Row>
        )}
      </Container>
    </StyledHeader>
  );
};

Header.defaultProps = {
  title: null,
  avatar: null,
};

export default Header;
