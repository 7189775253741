/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Button, Alert } from 'react-bootstrap';
import { Header, EmpresaModal, GeneralModal as SendCVModal, SubHeader } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import styled from 'styled-components';
import api from 'config/api';
import { filterObj, stripHtmlTags } from 'utils';
import _ from 'lodash';
import { Send } from 'react-feather';
import empresaIcon from 'assets/images/empresa_icon.png';
import Curriculo from 'pages/Curriculo';
import { getUserToken } from 'utils/storage';
import { vagasActions } from 'store/ducks';
import { useToasts } from 'react-toast-notifications';

// eslint-disable-next-line import/no-unresolved

const ListaDetalhes = styled(ListGroup)`
  background: transparent;
`;

const ListItem = styled(ListGroup.Item)`
  background: transparent;
  border: none;
  padding: 0;
  p {
    padding: 0;
    margin-bottom: 10px;
  }
`;

const InfoMessage = styled(Alert)`
  color: #167b9c;
  background-color: #e8e8e8;
  border-color: #167b9c;
  text-align: center;
`;

const LinkButton = styled.button`
  color: #0b4d83;
  background: transparent;
  border: none;
  padding: 0;
`;

const EmpresaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 25px;
    font-weight: 400;
  }
`;

const Title = styled.h2`
  font-family: 'Avenir Next Bold';
  margin-bottom: 25px;
  text-align: ${props => props.position || 'center'};
`;

const Box = styled.div`
  padding: 25px 0px;
  h2 {
    font-size: 22px;
    font-weight: 400;
  }
  p {
    color: #707070;
  }
`;

const Separador = styled.div`
  height: 50%;
  width: 1px;
  background: #707070;
`;

const CandBtn = styled(Button)`
  background: #102542;
  border-radius: 25px;
  border: none;
  transition: transform 0.5s;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:hover,
  &:active {
    background: #102542 !important;
    transform: scale(1.1);
    transition: transform 0.5s;
  }
`;

const VagaPage = ({ match, history }) => {
  const [visible, setVisible] = useState(false);
  const [visibleCV, setVisibleCV] = useState(false);
  const token = getUserToken();

  const curriculo = useSelector(state => state.curriculo);
  const constantes = useSelector(state => state.constantes);
  const submeterError = useSelector(state => state.vagas.submit_error);
  const submeterSuccess = useSelector(state => state.vagas.submit_success);
  const isCandidato = useSelector(state => state.vagas.is_candidato);
  const [vaga, setVaga] = useState({});
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleSubmitCV = () => {
    dispatch(vagasActions.submeterCV(curriculo, match.params.id));
    setVisibleCV(false);
  };

  useEffect(() => {
    const fetchVaga = async () => {
      const res = await api.get(`/vagas/${match.params.id}`);
      setVaga(res.data.data.vaga);
    };
    fetchVaga();
  }, [match.params.id]);

  useEffect(() => {
    if (vaga && vaga.id) {
      const fetchCandidaturas = async () => {
        const res = await api.get(`/candidatos/candidaturas`);
        return res.data.data.candidaturas;
      };
      const candidaturasPromise = fetchCandidaturas();
      candidaturasPromise.then(data => {
        dispatch(vagasActions.setIsCandidato(data.find(e => e.vaga.id === vaga.id) !== undefined));
      });
    }
  }, [vaga, dispatch]);

  useEffect(() => {
    if (submeterError) {
      addToast(`Erro: ${submeterError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch(vagasActions.submeterCVError(null));
    }
  }, [submeterError, dispatch, addToast]);

  useEffect(() => {
    if (submeterSuccess) {
      addToast(`Currículo enviado com sucesso!`, {
        appearance: 'success',
        autoDismiss: true,
      });
      dispatch(vagasActions.submeterCVSuccess(null));
    }
  }, [submeterSuccess, dispatch, addToast]);

  return (
    <>
      {!_.isEmpty(vaga) && (
        <>
          <Header />
          <SubHeader height="auto">
            <EmpresaContainer className="p-2">
              <img
                style={{ width: 'auto', maxHeight: '50px' }}
                src={vaga.empresa.logo || empresaIcon}
                alt="logo da empresa"
              />
              <h3 className="my-0 ml-3">{vaga.empresa.nome_fantasia}</h3>
            </EmpresaContainer>
          </SubHeader>
          <EmpresaModal visible={visible} setVisible={setVisible} empresa={vaga.empresa} />
          <SendCVModal title="Revise seu Currículo" visible={visibleCV} setVisible={setVisibleCV}>
            <Curriculo inModal />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-secondary"
                className="mr-2"
                onClick={() => setVisibleCV(false)}
              >
                Cancelar
              </Button>
              <Button variant="outline-success" onClick={handleSubmitCV}>
                Submeter
              </Button>
            </div>
          </SendCVModal>

          <Container className="py-5" color="#102542">
            <Row>
              <Col md={5}>
                <Box>
                  <Title className="title">{vaga.titulo}</Title>
                  <p dangerouslySetInnerHTML={{ __html: vaga.descricao }} />
                </Box>
                {token && isCandidato !== null && !isCandidato && vaga && (
                  <CandBtn variant="success" size="sm" onClick={() => setVisibleCV(true)}>
                    Candidatar-se
                    <Send size={16} style={{ marginLeft: 5 }} />
                  </CandBtn>
                )}
                {token && isCandidato && (
                  <InfoMessage variant="info">Você já se candidatou a essa vaga.</InfoMessage>
                )}
              </Col>
              <Col md={2} className="d-flex justify-content-center align-items-center">
                <Separador />
              </Col>
              <Col md={5}>
                <Box>
                  <Title position="left" className="title">
                    Detalhes da Vaga
                  </Title>
                  <ListaDetalhes>
                    <ListItem>
                      <span>Salário</span>
                      <p>R$ {vaga.salario}</p>
                    </ListItem>
                    <ListItem>
                      <span>Regime de contratação</span>
                      <p>
                        {filterObj(constantes.regime_de_contratacao, vaga.regime_de_contratacao)}
                      </p>
                    </ListItem>
                    <ListItem>
                      <span>Escolaridade</span>
                      <p>
                        {filterObj(constantes.nivel_de_escolaridade, vaga.nivel_de_escolaridade)}
                      </p>
                    </ListItem>
                    <ListItem>
                      <span>Data de publicação</span>
                      <p>{format(new Date(vaga.criado_em), 'dd/MM/yyyy')}</p>
                    </ListItem>
                    <ListItem>
                      <span>Última atualização</span>
                      <p>{format(new Date(vaga.ultima_atualizacao_em), 'dd/MM/yyyy')}</p>
                    </ListItem>
                  </ListaDetalhes>
                  <LinkButton
                    color="#048ba8"
                    onClick={() => history.push(`/empresas/${vaga.empresa.id}`)}
                  >
                    <strong>Detalhes da Empresa</strong>
                  </LinkButton>
                </Box>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default VagaPage;
