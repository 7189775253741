const INITIAL_STATE = {
  page: 1,
  data: [],
  links: {
    next: null,
    previous: null,
  },
  count: 0,
  noticia: null,
};

export const Types = {
  async: {
    PEGAR_NOTICIAS: 'NOTICIAS/PEGAR_NOTICIAS_ASYNC',
    PEGAR_NOTICIA: 'NOTICIAS/PEGAR_NOTICIA_ASYNC',
  },
  sync: {
    PEGAR_NOTICIAS: 'NOTICIAS/PEGAR_NOTICIAS_SYNC',
    PEGAR_NOTICIA: 'NOTICIAS/PEGAR_NOTICIA_SYNC',
  },
};

export const Actions = {
  pegarNoticias: (page = 1) => ({ type: Types.async.PEGAR_NOTICIAS, payload: page }),
  pegarNoticia: (id = 1) => ({ type: Types.async.PEGAR_NOTICIA, payload: id }),
};

const noticiasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.PEGAR_NOTICIAS:
      return { ...state, ...action.payload };
    case Types.sync.PEGAR_NOTICIA:
      return { ...state, noticia: action.payload };
    default:
      return state;
  }
};

export default noticiasReducer;
