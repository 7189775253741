/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { perfilActions } from 'store/ducks';
import { Spinner } from 'react-bootstrap';
import { pegarEndereco } from 'services/perfilusuario';
import { localPadrao, cepLead } from 'utils/constantes';
import { useToasts } from 'react-toast-notifications';
import { CardItem, CardButton, CardInput, CardLabel } from '../styles';

const EnderecoForm = ({ endereco }) => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [cep, setCep] = useState();
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState(localPadrao.localidade);
  const [numero, setNumero] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [formValidate, setFormValidate] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleBairro = event => {
    setBairro(event.target.value);
  };

  const handleNumero = event => {
    setNumero(event.target.value);
  };

  const handleLogradouro = event => {
    setLogradouro(event.target.value);
  };

  const verificaLocal = dados =>
    dados && dados.uf === localPadrao.estado && dados.localidade === localPadrao.localidade;

  const handleCep = event => {
    let cepTemp = event.target.value;
    setCep(cepLead(cepTemp));
    const validacep = /^[0-9]{8}$/;
    cepTemp = cepTemp.replace(/\D/g, '');
    setCidade(localPadrao.localidade);
    if (validacep.test(cepTemp)) {
      pegarEndereco(cepTemp).then(res => {
        setLoading(true);
        if (!res.erro && verificaLocal(res.data)) {
          setTimeout(() => {
            const dados = res.data;
            setBairro(dados.bairro);
            setLogradouro(dados.logradouro);
            setFormValidate(true);
            setLoading(false);
          }, 2000);
        } else {
          setTimeout(() => {
            addToast(`O CEP da rua deve ser de ${localPadrao.localidade}/${localPadrao.estado}`, {
              appearance: 'error',
              autoDismiss: true,
            });
            setFormValidate(false);
            setLoading(false);
          }, 2000);
        }
      });
    }
  };

  useEffect(() => {
    if (endereco) {
      setCidade(endereco.localidade);
      setBairro(endereco.bairro);
      setNumero(endereco.numero);
      setLogradouro(endereco.logradouro);
    }
  }, [endereco]);

  const onSubmit = data => {
    if (data && formValidate) {
      // eslint-disable-next-line no-param-reassign
      data.estado = localPadrao.estado;
      dispatch(perfilActions.atualizarEndereco(data));
      addToast(`Dados salvos!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`O formulário não foi preenchido corretamente`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      {loading && (
        <div className="w-100 d-flex justify-content-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {!loading && (
        <form onSubmit={handleSubmit(onSubmit)} className="w-100 d-grid">
          <div className="row">
            <CardItem className="col-xs-12 col-sm-3 col-lg-2">
              <div className="input-group mb-2 d-flex flex-column">
                <CardLabel className="mb-0 d-flex align-items-center" htmlFor="cep">
                  CEP
                </CardLabel>
                <CardInput
                  type="text"
                  className="form-control w-100"
                  name="cep"
                  ref={register({
                    pattern: {
                      value: /[\d\-.]{10}/,
                      message: 'Formato de CEP inválido',
                    },
                  })}
                  placeholder="00.000-000"
                  value={cep}
                  onChange={handleCep}
                />
              </div>
              <div className="text-danger text-left mb-2">
                <ErrorMessage errors={errors} name="cep" />
              </div>
            </CardItem>
            <CardItem className="col-xs-12 col-sm-7 col-lg-9">
              <div className="input-group mb-2 d-flex flex-column">
                <CardLabel className="mb-0 d-flex align-items-center" htmlFor="logradouro">
                  Logradouro <span className="gold_star">*</span>
                </CardLabel>
                <CardInput
                  type="text"
                  className="form-control w-100"
                  name="logradouro"
                  ref={register({ required: 'Por favor, informe o nome de sua rua.' })}
                  placeholder="Nome da rua"
                  value={logradouro}
                  onChange={handleLogradouro}
                />
              </div>
              <div className="text-danger text-left mb-2">
                <ErrorMessage errors={errors} name="logradouro" />
              </div>
            </CardItem>
            <CardItem className="col-xs-12 col-sm-2 col-lg-1">
              <div className="input-group mb-2 d-flex flex-column">
                <CardLabel className="mb-0 d-flex align-items-center" htmlFor="numero">
                  Nº <span className="gold_star">*</span>
                </CardLabel>
                <CardInput
                  type="text"
                  className="form-control w-100"
                  name="numero"
                  ref={register({ required: 'Por favor, informe o número da casa.' })}
                  placeholder="Número"
                  value={numero}
                  onChange={handleNumero}
                />
              </div>
              <div className="text-danger text-left mb-2">
                <ErrorMessage errors={errors} name="numero" />
              </div>
            </CardItem>
          </div>
          <div className="row">
            <CardItem className="col-xs-12 col-md-6">
              <div className="input-group mb-2 d-flex flex-column">
                <CardLabel className="mb-0 d-flex align-items-center" htmlFor="bairro">
                  Bairro <span className="gold_star">*</span>
                </CardLabel>
                <CardInput
                  type="text"
                  className="form-control w-100"
                  name="bairro"
                  ref={register({ required: 'Por favor, informe seu bairro.' })}
                  placeholder="Informe seu bairro"
                  value={bairro}
                  onChange={handleBairro}
                />
              </div>
              <div className="text-danger text-left mb-2">
                <ErrorMessage errors={errors} name="bairro" />
              </div>
            </CardItem>
            <CardItem className="col-xs-12 col-md-6">
              <div className="input-group mb-2 d-flex flex-column">
                <CardLabel className="mb-0 d-flex align-items-center" htmlFor="cidade">
                  Cidade
                </CardLabel>
                <CardInput
                  type="text"
                  className="form-control w-100"
                  name="cidade"
                  ref={register({ required: 'Por favor, informe sua cidade.' })}
                  placeholder="Informe sua cidade"
                  value={cidade || ''}
                  onChange={null}
                  disabled
                />
              </div>
              <div className="text-danger text-left mb-2">
                <ErrorMessage errors={errors} name="cidade" />
              </div>
            </CardItem>
          </div>

          <CardItem className="col-xs-12 d-flex justify-content-end">
            <CardButton className="btn btn-primary shadow-2 mb-2 mt-2" type="submit">
              Atualizar
            </CardButton>
          </CardItem>
        </form>
      )}
    </>
  );
};

export default EnderecoForm;
