/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, ErrorMessage } from 'react-hook-form';
import { authActions } from 'store/ducks';
import { setUpAfterLogin } from 'services/auth';
import api from 'config/api';
import logoemprega from 'assets/images/logoemprega.png';
import { Link } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';
import FacebookLogin from 'react-facebook-login';
import { AuthWrapper, Input, Button, Triangle } from '../styles';

const SignIn = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState(false);

  const showError = () => {
    setError(true);

    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const onSubmit = data => {
    api
      .post('/signin', data)
      .then(response => {
        setUpAfterLogin(response.data.data);
        dispatch(authActions.login(response.data.data));
      })
      .catch(() => {
        showError();
      });
  };

  const loginWithFacebook = ({ status, accessToken }) => {
    if (accessToken && status !== 'unknown') {
      api
        .post('/signin/facebook', { access_token: accessToken })
        .then(response => {
          setUpAfterLogin(response.data.data);
          dispatch(authActions.login(response.data.data));
        })
        .catch(() => {
          showError();
        });
    }
  };

  React.useEffect(() => {
    if (auth.token) {
      history.push('/');
    }
  }, [auth, history]);

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    if (localStorage.getItem('user_token')) {
      // eslint-disable-next-line react/prop-types
      history.push('/');
    }
    // eslint-disable-next-line no-undef
  }, [localStorage.getItem('user_token'), history]);

  // eslint-disable-next-line no-undef
  if (localStorage.getItem('user_token')) {
    return null;
  }

  return (
    <AuthWrapper className="signInAuthWrapper m-0 bg-white d-flex flex-column justify-content-around align-items-center w-100">
      <Triangle className="left" />
      <Triangle className="right" />
      <Button
        type="submit"
        style={{ position: 'absolute', top: 20, right: 20, color: '#102542' }}
        onClick={() => history.goBack()}
        className="btn px-3 d-flex justify-content-center align-items-center"
      >
        <TiArrowBack size={30} />
        <span style={{ transform: 'translate(0px, 3px)' }}>Voltar</span>
      </Button>
      <div className="card bg-transparent border-0" style={{ width: 275 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-body text-center d-flex flex-column justify-content-between align-items-center p-0">
            <img src={logoemprega} alt="logo do site" width={150} />
            <div className="w-100 my-4">
              <div className="input-group mb-1">
                <Input
                  type="email"
                  className="form-control w-100 mb-2 signIn py-1 px-3"
                  ref={register({ required: 'Por favor, insira seu email' })}
                  name="email"
                  placeholder="Email"
                />
                <span className="text-danger d-block w-100">
                  <ErrorMessage errors={errors} name="email" />
                </span>
              </div>
              <div className="input-group text-center">
                <Input
                  type="password"
                  className="form-control w-100 mb-2 signIn py-1 px-3"
                  ref={register({ required: 'Por favor, insira sua senha' })}
                  name="senha"
                  placeholder="Senha"
                />
                <span className="text-danger d-block w-100">
                  <ErrorMessage errors={errors} name="senha" />
                </span>
              </div>
              {error && <span className="text-danger d-block mb-2">Email ou senha incorreto</span>}
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center mb-3">
              <Button type="submit" className="btn btn-primary shadow-2 px-5">
                Entrar
              </Button>
              <Link style={{ textDecoration: 'none' }} to="/recuperar_senha" className="mx-auto">
                <small style={{ color: '#102542' }}>Recuperar Senha</small>
              </Link>
            </div>

            <FacebookLogin
              appId="373984250128427"
              fields="name,email"
              textButton="Entrar com Facebook"
              callback={loginWithFacebook}
              cssClass="facebook-button"
              icon="fa-facebook"
            />

            <div className="mx-auto d-flex flex-column align-items-center justify-content-center">
              <span>Ainda não tem conta?</span>
              <Link style={{ textDecoration: 'none' }} color="048ba8" to="/cadastro">
                Faça seu cadastro!
              </Link>
            </div>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;
