/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import empresaIcon from 'assets/images/empresa_icon.png';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const Card = styled.div`
  width: 18%;
  min-width: 300px;
  height: fit-content;
  background-color: white;
  margin: 25px;
  border-radius: 7px;
  transition: opacity 0.4s;

  &:hover {
    transition: opacity 0.4s;
    opacity: 0.5;
  }

  & > a {
    text-decoration: none;
    color: black;

    & > .header {
      background: #048ba8;
      border-radius: 7px 7px 0px 0px;
      padding: 10px;
      h5 {
        margin: 0px;
        color: #fff;
        font-size: 15px;
      }
    }

    & > .body {
      display: flex;

      div {
        padding: 15px;
        width: 50%;
        text-align: center;
      }
    }
  }
`;

const EmpresaCard = ({ empresa }) => {
  return (
    <Card className="shadow-sm">
      <Link to={`/empresas/${empresa.id}`}>
        <div className="header">
          <h5>{empresa.nome_fantasia}</h5>
        </div>
        <div
          className="body py-2 d-flex justify-content-around align-items-center"
          style={{ height: 80, boxSizing: 'content-box' }}
        >
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">{empresa.nome_fantasia}</Tooltip>}
          >
            <img
              style={{ maxWidth: '100px', maxHeight: '80px' }}
              src={empresa.thumbnail || empresaIcon}
              alt={empresa.nome_fantasia}
            />
          </OverlayTrigger>
          <div
            className="px-0"
            style={{ height: '30px', width: '1px', borderLeft: '1px solid gray' }}
          />
          <div className="px-0" style={{ width: 'fit-content' }}>
            <span>{empresa.total_vagas_em_aberto} Vagas</span>
            <small className="d-block">ofertadas</small>
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default EmpresaCard;
