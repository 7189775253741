/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filterObj } from 'utils';
import empresaIcon from 'assets/images/empresa_icon.png';
import useWindowDimensions from 'components/windowDimensions';
import { CardHeader, CardTitle, StyledCard, Separator, CardLink, RegimeIcon } from './styles';

const CardVaga = ({ vaga, dadosEmpresa }) => {
  const constantes = useSelector(state => state.constantes);
  const { width } = useWindowDimensions();
  const [ismobile, setIsMobile] = useState(width < 520);
  const { nome, logo } = dadosEmpresa || { nome: undefined, logo: undefined };

  useEffect(() => {
    setIsMobile(width < 520);
  }, [width]);

  return (
    <CardLink to={`/vaga/${vaga.id}`}>
      <StyledCard
        ismobile={ismobile ? 1 : 0}
        className="d-flex flex-row justify-content-between align-items-center py-1 px-3"
      >
        <div style={{ width: 'calc(100% - 10em)' }}>
          <CardHeader
            className={`${
              ismobile ? 'px-0' : 'px-2'
            } pt-3 pb-0 w-100 d-flex flex-column justify-content-around align-items-start w-100{`}
          >
            <CardTitle className="mb-0">{vaga.titulo}</CardTitle>
            <small style={{ color: 'gray' }}>{nome || vaga.empresa.nome_fantasia}</small>
          </CardHeader>
          <StyledCard.Body
            className={`${ismobile &&
              'p-0'} p-2 pt-0 d-flex justify-content-between align-items-center w-100`}
          >
            <div className="d-flex justify-content-start align-items-center pb-2">
              {vaga.regime_de_contratacao && (
                <RegimeIcon
                  small={parseInt(vaga.regime_de_contratacao, 10) === 0}
                  className="rounded-circle p-2 mr-2 d-flex justify-content-center align-items-center"
                >
                  <span className="regime-icon text-white text-center">
                    <p>{filterObj(constantes.regime_de_contratacao, vaga.regime_de_contratacao)}</p>
                  </span>
                </RegimeIcon>
              )}
              {vaga.salario && (
                <span style={{ minWidth: 'fit-content' }}>
                  <p>{`${!Number.isNaN(vaga.salario) ? 'R$ ' : ''}${vaga.salario}`}</p>
                </span>
              )}
              {vaga.nivel_de_escolaridade && (
                <>
                  <span className="d-flex align-items-center">
                    {vaga.salario && <Separator inline />}
                    <p>{filterObj(constantes.nivel_de_escolaridade, vaga.nivel_de_escolaridade)}</p>
                  </span>
                </>
              )}
              {vaga.vaga_para_portadores_de_necessidades_especiais && (
                <>
                  <span className="d-flex align-items-center">
                    <Separator inline />
                    <p>PCD</p>
                  </span>
                </>
              )}
            </div>
          </StyledCard.Body>
        </div>
        <img
          style={{ maxWidth: '10.0em', maxHeight: '8.0em' }}
          src={logo || (vaga.empresa && vaga.empresa.thumbnail) || empresaIcon}
          alt={nome || (vaga.empresa && vaga.empresa.nome_fantasia)}
        />
      </StyledCard>
    </CardLink>
  );
};

export default CardVaga;
