import { takeLatest, call, put } from 'redux-saga/effects';
import { atualizarTopico, pegarPerfil } from 'services/curriculo';
import { Types } from '../ducks/curriculo';
import { Types as AppTypes } from '../ducks/app';

const buildPayload = candidato => {
  return (
    candidato && {
      objetivo: candidato.objetivo,
      formacao: candidato.formacao,
      experiencia_profissional: candidato.experiencia_profissional,
      idiomas: candidato.idiomas,
      qualificacoes: candidato.qualificacoes,
      informacoes_adicionais: candidato.informacoes_adicionais,
    }
  );
};

function* atualizarTopicoAsync({ payload }) {
  try {
    const { data } = yield call(atualizarTopico, payload);
    yield put({ type: Types.sync.ATUALIZAR_TOPICO, payload: buildPayload(data.data.candidato) });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* pegarPerfilAsync() {
  try {
    const { data } = yield call(pegarPerfil);
    yield put({ type: Types.sync.PEGAR_TOPICOS, payload: buildPayload(data.data.candidato) });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* empresasSaga() {
  yield takeLatest(Types.async.PEGAR_TOPICOS, pegarPerfilAsync);
  yield takeLatest(Types.async.ATUALIZAR_TOPICO, atualizarTopicoAsync);
}

export default empresasSaga;
