import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Header, Sidebar, ListaVagas, SearchBar, SubHeader } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import CarouselCpnt from 'components/Carousel';
import { noticiasActions } from 'store/ducks';

const HomePage = () => {
  const dispatch = useDispatch();
  const filtros = useSelector(state => state.vagas.filtros);
  const constantes = useSelector(state => state.constantes);
  const noticias = useSelector(state => state.noticias.data.slice(0, 5));

  useEffect(() => {
    dispatch(noticiasActions.pegarNoticias());
  }, []);

  let [title, sideBarData] = ['', {}];

  const proccessFilters = () => {
    if (filtros.length) {
      const tempFilters = filtros.map(f => [f.split('__')[0], f.split('__')[1].split('=')[1]]);
      title = tempFilters.find(e => e[0] === 'titulo');

      sideBarData = {};
      tempFilters
        .filter(f => !['titulo'].includes(f[0]))
        .map(f => [
          f[0],
          f[0] === 'salario'
            ? { min: parseInt(f[1].split(',')[0], 10), max: parseInt(f[1].split(',')[1], 10) }
            : f[1].split(',').map(e => ({ value: e, label: constantes[f[0]][e] })),
        ])
        .forEach(e => {
          // eslint-disable-next-line prefer-destructuring
          sideBarData[e[0]] = e[1];
        });
    }
  };

  proccessFilters();

  useEffect(() => {
    proccessFilters();
  }, [filtros]);

  return (
    <>
      {noticias && noticias.length > 0 ? <Header hideBg /> : <Header />}
      {noticias && noticias.length > 0 && (
        <CarouselCpnt
          style={{ marginTop: 50 }}
          listItems={noticias.map(e => ({ id: e.id, imagem: e.imagem, titulo: e.titulo }))}
        />
      )}
      <SubHeader title="VAGAS DISPONÍVEIS" />
      <Container className="pt-5">
        <Row>
          <Col lg={5} md={12}>
            <Sidebar sideBarData={sideBarData} />
          </Col>
          <Col lg={7} md={12}>
            <SearchBar title={title && title[1]} />
            <ListaVagas />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
