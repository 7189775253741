import { takeLatest, call, put, select, take, all } from 'redux-saga/effects';
import { listarCandidaturas, filtrarCandidaturas, deleteCandidatura } from 'services/candidaturas';
import { Types } from '../ducks/candidaturas';
import { Types as AppTypes } from '../ducks/app';

function* listarCandidaturasAsync({ payload }) {
  try {
    const filtros = yield select(state => state.candidaturas.filtros);
    const query = filtros.join('&');
    // yield put({ type: AppTypes.SET_VAGAS_LOADING });
    const { data } = yield call(listarCandidaturas, payload, query);
    const buildPayload = {
      data: data.data.candidaturas,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: Types.sync.LISTAR, payload: buildPayload });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* removeCandidaturasAsync({ payload }) {
  try {
    const { data } = yield call(deleteCandidatura, payload);
    yield put({ type: Types.async.LISTAR, payload: 1 });
    yield put({
      type: AppTypes.SET_TOASTR,
      payload: { message: data.message, abled: true, status: 'success' },
    });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* filtrarCandidaturasAsync() {
  try {
    const filtros = yield select(state => state.vagas.filtros);
    const query = filtros.join('&');
    yield put({ type: AppTypes.SET_VAGAS_LOADING });

    const { data } = yield call(filtrarCandidaturas, query);
    const buildPayload = {
      data: data.data.vagas,
      count: data.data.count,
      links: data.data.links,
    };
    yield put({ type: Types.sync.FILTRAR, payload: buildPayload });
    yield put({ type: AppTypes.SET_VAGAS_LOADING_FiNISHED });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* watchRemoveFiltro() {
  while (true) {
    yield take(Types.sync.REMOVE_FILTRO);
    yield call(filtrarCandidaturasAsync);
  }
}

function* watchAddFiltro() {
  while (true) {
    yield take(Types.sync.ADD_FILTRO);
    yield call(filtrarCandidaturasAsync);
  }
}

function* vagasSaga() {
  yield takeLatest(Types.async.LISTAR, listarCandidaturasAsync);
  yield takeLatest(Types.async.REMOVER, removeCandidaturasAsync);
  yield all([watchAddFiltro(), watchRemoveFiltro()]);
}

export default vagasSaga;
