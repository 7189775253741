const INITIAL_STATE = {
  mensagens: [],
};

export const Types = {
  async: {
    PEGAR_MENSAGENS: 'MENSAGENS/PEGAR_MENSAGENS_ASYNC',
  },
  sync: {
    PEGAR_MENSAGENS: 'MENSAGENS/PEGAR_MENSAGENS_SYNC',
  },
};

export const Actions = {
  pegarMensagens: (page = 1) => ({ type: Types.async.PEGAR_MENSAGENS, payload: page }),
};

const mensagensReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.PEGAR_MENSAGENS:
      return { ...state, mensagens: action.payload };
    default:
      return state;
  }
};

export default mensagensReducer;
