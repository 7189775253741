/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { X } from 'react-feather';
import sanitizeHtml from 'sanitize-html-react';
import { ModalBody, ModalHeader, LinkButton } from './styles';

const stripHtml = text => {
  return sanitizeHtml(text, {
    allowedTags: [],
  });
};

const EmpresaModal = ({ visible, setVisible, empresa }) => {
  return (
    <>
      <Modal show={visible} dialogClassName="modal-90w" centered onHide={() => setVisible(false)}>
        <ModalHeader className="text-center">
          <h3>{empresa.nome_fantasia}</h3>
        </ModalHeader>
        <LinkButton onClick={() => setVisible(false)}>
          <X />
        </LinkButton>
        <ModalBody>
          <div>{stripHtml(empresa.descricao)}</div>

          <address>
            <h4>Endereço</h4>
            <p>
              {empresa.endereco.logradouro} - {empresa.endereco.numero}
            </p>
            <p>
              {empresa.endereco.cidade} - {empresa.endereco.bairro}
            </p>
            <p>
              {empresa.endereco.estado} - {empresa.endereco.pais}
            </p>
          </address>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmpresaModal;
