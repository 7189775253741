/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Carousel, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';

const CarouselItem = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${props => props.bgImg});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position-y: -100px;
  height: 200px;
`;

const CarouselCaption = styled(Carousel.Caption)`
  display: flex;
  bottom: 15%;
  top: 15%;
  justify-content: center;
  font-family: 'Avenir Next Bold';
  text-shadow: 0 0 20px black;

  & > p {
    font-size: 18px;
  }
`;

const CarouselCpnt = ({ listItems = [], ...props }) => {
  const [index, setIndex] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      {listItems.length ? (
        <Carousel
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          nextIcon={<ChevronRight size={80} />}
          prevIcon={<ChevronLeft size={80} />}
          activeIndex={index}
          onSelect={handleSelect}
        >
          {listItems.map(e => (
            <Carousel.Item key={e.id}>
              <Link to={`/noticias/${e.id}`}>
                <CarouselItem bgImg={'https://tinderdoemprego.s3-sa-east-1.amazonaws.com/26293.jpg'} />
                <CarouselCaption className="d-flex justify-content-center align-items-center">
                  <p className="mb-0">{e.titulo}</p>
                </CarouselCaption>
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div
          // eslint-disable-next-line react/prop-types
          style={{ height: 150, ...props.style }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner style={{ marginTop: '0 !important' }} animation="border" role="status" />
          <span className="ml-3 text-black">Carregando notícias ...</span>
        </div>
      )}
    </>
  );
};

export default CarouselCpnt;
