import React from 'react';
import styled from 'styled-components';
import { Fields, IFilter } from 'utils/filtros';
import { useDispatch } from 'react-redux';
import { candidaturasActions } from 'store/ducks';
import _ from 'lodash';

const SearchInput = styled.input`
  border-radius: 35px;
  text-align: center;
  border: none;
  margin: 25px;
  width: 80%;
  &:focus {
    outline: none;
  }
`;

const SearchEmpresa = () => {
  const dispatch = useDispatch();

  const debouncedSearch = _.debounce(value => {
    dispatch(candidaturasActions.addFiltro(IFilter.icontains(Fields.titulo, value)));
  }, 1000);

  const handleChange = e => {
    const { value } = e.target;
    debouncedSearch(value);
  };

  return (
    <div>
      <SearchInput placeholder="Buscar candidaturas..." onChange={handleChange} />
    </div>
  );
};

export default SearchEmpresa;
