import { takeLatest, call, put } from 'redux-saga/effects';
import { listarMensagens } from 'services/mensagens';
import { Types as MensagensTypes } from '../ducks/mensagens';
import { Types as AppTypes } from '../ducks/app';

function* pegarMensagensAsync({ payload }) {
  try {
    const { data } = yield call(listarMensagens, payload);
    yield put({ type: MensagensTypes.sync.PEGAR_MENSAGENS, payload: data.data.mensagens });
  } catch (e) {
    yield put({ type: AppTypes.SET_ERROR, payload: e });
  }
}

function* mensagensSagas() {
  yield takeLatest(MensagensTypes.async.PEGAR_MENSAGENS, pegarMensagensAsync);
}

export default mensagensSagas;
