/* eslint-disable no-case-declarations */
import buildQuery from 'utils/filtros';
import _ from 'lodash';

const INITIAL_STATE = {
  page: 1,
  data: [],
  filtros: [],
  links: {
    next: null,
    previous: null,
  },
  count: 0,
};

export const Types = {
  async: {
    LISTAR: 'empresas/listar_async',
    FILTRAR: 'empresas/filtrar_async',
  },
  sync: {
    LISTAR: 'empresas/listar',
    FILTRAR: 'empresas/filtrar_sync',
    ADD_FILTRO: 'empresas/adicionar_filtro',
    REMOVE_FILTRO: 'empresas/remover_filtro',
  },
};

// Actions Creators
export const Actions = {
  listarEmpresas: (page = 1, query = '') => ({
    type: Types.async.LISTAR,
    payload: { page, query },
  }),
  filtarEmpresas: () => {
    return { type: Types.async.FILTRAR };
  },
  addFiltro: filtro => {
    const query = buildQuery(filtro);
    return { type: Types.sync.ADD_FILTRO, payload: query };
  },
  removeFiltro: filtro => {
    return { type: Types.sync.REMOVE_FILTRO, payload: filtro };
  },
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.LISTAR:
      return { ...state, ...action.payload };
    case Types.sync.FILTRAR:
      return { ...state, ...action.payload };
    case Types.sync.ADD_FILTRO:
      const el = _.find(state.filtros, item =>
        item.includes(action.payload.substring(0, action.payload.indexOf('=')))
      );
      if (el) return { ...state, filtros: [..._.without(state.filtros, el), action.payload] };
      return { ...state, filtros: [...state.filtros, action.payload] };

    case Types.sync.REMOVE_FILTRO:
      return {
        ...state,
        filtros: _.without(
          state.filtros,
          state.filtros.find(item => item.includes(action.payload))
        ),
      };
    default:
      return state;
  }
};
