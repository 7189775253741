import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledCard = styled(Card)`
  padding-top: 1.5em;
  background: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 5px;
  transition: background 0.5s;
  border: none;
  box-shadow: 0 0 7px #efe7e7;
  font-size: ${props => (props.ismobile ? '42.5%' : '62.5%')};
  p {
    padding: 0;
    margin: 0;
    color: #373a3c;
    font-size: 1.45em;
  }

  &:hover {
    background: #e2e2e282;
    transition: background 0.5s;
  }

  span:not(.regime-icon) {
    p {
      color: #048ba8;
      font-size: 1.5em;
    }
  }
`;

export const RegimeIcon = styled.div`
  background: rgb(4, 139, 168);
  min-width: 3em;
  min-height: 3em;
  max-width: 3em;
  max-height: 3em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;

  & > span p {
    ${props => props.small && 'font-size: 0.8em !important;'}
    line-break: auto;
    color: white;
    transform: translate(0px, 1.5px);
  }
`;

export const CardLink = styled(Link)`
  &:hover {
    text-decoration: none !important;
  }
`;

export const Separator = styled.div`
  background: #eec643;
  border-radius: 25px;
  width: 5px;
  height: 5px;
  margin: 0 0.5em;
  transform: translate(0, -2.5px);
  display: ${props => (props.inline ? 'inline-' : '')}block;
`;

export const CardHeader = styled(Card.Header)`
  border: none;
  color: #102542;
  background: none;
  font-family: 'Avenir Next Bold';

  small {
    font-size: 1.3em;
  }
`;

export const CardTitle = styled.h5`
  text-overflow: ellipsis;
  font-size: 2em;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;
