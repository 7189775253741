/* eslint-disable no-case-declarations */
import buildQuery from 'utils/filtros';
import _ from 'lodash';

const INITIAL_STATE = {
  page: 1,
  data: [],
  filtros: [],
  links: {
    next: null,
    previous: null,
  },
  count: 0,
  loading: false,
};

export const Types = {
  async: {
    LISTAR: 'candidaturas/listar_async',
    FILTRAR: 'candidaturas/filtrar_async',
    REMOVER: 'candidaturas/remover_async',
  },
  sync: {
    LISTAR: 'candidaturas/listar_sync',
    FILTRAR: 'candidaturas/filtrar_sync',
    ADD_FILTRO: 'candidaturas/adicionar_filtro',
    REMOVE_FILTRO: 'candidaturas/remover_filtro',
  },
};

// Actions Creators
export const Actions = {
  listarCandidaturas: (page = 1) => ({ type: Types.async.LISTAR, payload: page }),
  filtarCandidaturas: () => {
    return { type: Types.async.FILTRAR };
  },
  removerCandidatura: (vagaId, candidaturaId) => ({
    type: Types.async.REMOVER,
    payload: { vagaId, candidaturaId },
  }),
  addFiltro: filtro => {
    const query = buildQuery(filtro);
    return { type: Types.sync.ADD_FILTRO, payload: query };
  },
  removeFiltro: filtro => {
    return { type: Types.sync.REMOVE_FILTRO, payload: filtro };
  },
};

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.sync.LISTAR:
      return { ...state, ...action.payload };
    case Types.sync.FILTRAR:
      return { ...state, ...action.payload };
    case Types.sync.ADD_FILTRO:
      const el = _.find(state.filtros, item =>
        item.includes(action.payload.substring(0, action.payload.indexOf('=')))
      );

      if (el) return { ...state, filtros: [..._.without(state.filtros, el), action.payload] };
      return { ...state, filtros: [...state.filtros, action.payload] };

    case Types.sync.REMOVE_FILTRO:
      return {
        ...state,
        filtros: _.without(
          state.filtros,
          state.filtros.find(item => item.includes(action.payload))
        ),
      };

    default:
      return state;
  }
};
